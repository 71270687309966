import React , { useState, FunctionComponent, ReactElement, FormEvent, ChangeEvent} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Button , Radio, RadioProps} from '@material-ui/core';
import {User} from '../../services/auth-service';
import { useStyles } from './useStyles';

const CustomRadio = withStyles({
    root: {
      color: '#000000',
      '&$checked': {
        color: '#000000',
      },
      padding: 0
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);

type AccountCardProps = User & { 
    classNames:{
        account_card:string,
        checkbox:string,
        text_presentation:string,
        column_1:string,
        column_2:string,
    };
    onChangeHandler:(event: ChangeEvent<HTMLInputElement>) =>void;
    selectedValue:string;
};

interface AccountsFormOptions{
  email:string;
  users:User[];
  selectAccountHandler:(accountGuid:string)=>void;
  loading:boolean;
  selectedUser:User | null;
}


const AccountCard:FunctionComponent<AccountCardProps>= ({guid, name, phone, classNames, onChangeHandler, selectedValue}):ReactElement=>{

    return (
       <div className={classNames.account_card}>
           <div className={classNames.checkbox}>
            <CustomRadio
                checked={selectedValue === guid}
                onChange={onChangeHandler}
                value={guid}
                name={`card-item-${guid}`}
            />
           </div>
           <div className={`${classNames.text_presentation} ${classNames.column_1}`}>
             <label>Name</label>
             <label>Telefon</label>
           </div>
           <div className={`${classNames.text_presentation} ${classNames.column_2}`}>
             <label>{name}</label>
             <label>{phone}</label>
           </div>
       </div>
    )
}

export const AccountsForm:FunctionComponent<AccountsFormOptions>= (props:AccountsFormOptions):ReactElement=>{
   const { users , email,  selectAccountHandler, loading, selectedUser} = props;
   const [selectedAccount , setSelectedAccount] = useState<string>(selectedUser ? selectedUser.guid : users[0].guid);
   const classes = useStyles();

   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedAccount(event.target.value);
  };


   const submitHandler = (event:FormEvent)=>{
     if(loading){
       return;
     }
     event.preventDefault();
     selectAccountHandler(selectedAccount);
   }

   return (
    <form className={classes.form} noValidate onSubmit={submitHandler}>
      <div className={classes.accounts_container}>
      <div className={classes.email_view}>
        <span>E-mail</span>
        <span>{email}</span>
      </div>
      {
        users.map( user => 
          <AccountCard 
            {...user} 
            classNames={{
              account_card:classes.account_card,
              checkbox:classes.checkbox,
              text_presentation:classes.text_presentation,
              column_1:classes.column_1,
              column_2:classes.column_2
            }} 
            onChangeHandler={handleChange} 
            selectedValue={selectedAccount}
          />
        )
       }
      </div>
      <Button type='submit' disabled={loading} fullWidth variant='contained' className={`${classes.submit}`}>
        Sende SMS code
      </Button>
    </form>
   )
};