import React, { ChangeEvent, FocusEvent, FormEvent, FunctionComponent, ReactElement, useState, ReactNode } from 'react';
import { Button, TextField } from '@material-ui/core';
import { useStyles } from './useStyles';
import { validateEmail } from '../../unit/constants';
import {LabelContent} from './label-content';

interface EmailFormProps {
  onSubmit(phone: string): void;
  loading:boolean;
}

const EmailForm: FunctionComponent<EmailFormProps> = (props: EmailFormProps): ReactElement => {
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  const [focus, setFocus] = useState<number>(0);

  const submitHandler = (event: FormEvent): void => {
    if(props.loading){
      return;
    }
    event.preventDefault();
    if (email) {
      props.onSubmit(email.trim());
    }
  };

  const changeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setEmail(value);
  };

  const focusHandler = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>):void=>{
    setFocus( prev => prev + 1)
  }

  const blurHandler =(event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>):void=>{
    setFocus( prev => prev - 1)
  }

  const valid = validateEmail(email);
  const hasError = !valid && email.length > 0;

  const labelContent = ():ReactNode=>{
    return <LabelContent focus={focus} labelName={'E-mail'}/>
  }

  return (
    <form className={classes.form} noValidate onSubmit={submitHandler}>
      <TextField
        variant='outlined'
        margin='normal'
        error={hasError}
        value={email}
        onChange={changeHandler}
        fullWidth
        id='email'
        label={labelContent()}
        name='email'
        autoFocus
        className={classes.input}
        onFocus={focusHandler}
        onBlur={blurHandler}
      />
      <Button type='submit' fullWidth variant='contained' disabled={!valid || props.loading} className={`${classes.submit} ${classes.mail_spacing}`}>
        Sende SMS code
      </Button>
    </form>
  );
};

export default EmailForm;
