import React, {useState, useEffect, FunctionComponent, ReactElement, ReactSVGElement, MouseEvent, PropsWithChildren} from 'react';
import { useHistory , useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {RootState} from '../../reducers';
import {Document, pdfjs , Page } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { OnLoadProgressArgs } from 'react-pdf/dist/cjs/shared/types';
import {Button, Typography} from '@material-ui/core';
import Header from '../header';
import Footer from '../footer';
import {LoadProgress} from '../load-progress';
import Loader from '../loader';
import {ArrowBack} from '../login/login';
import AuthService from '../../services/auth-service';
import SharepointService from '../../services/sharepoint-service';
import {SHAREPOINT_SERVICE_URI} from '../../unit/url';
import * as Labels from '../../unit/labels';
import {Statuses , getErrorMessage} from '../../unit/constants';
import {useMediaQueryHook} from '../../hooks/useMediaQuery';
import Overlay from '../overlay';
import {withSnackbar , SnackbarProps} from '../../hoc/withSnackbar';
import {DseState} from '../../reducers/dse';
import classes from './document.module.scss';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// or you can use this newest version `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.js`;



interface PageItemProps{
    index: number,
    pagesNumber:number,
    scale:number
}

type ErrorMessageProps = PropsWithChildren<{
    loading:boolean;
    message:string;
    reloadHandler:()=>void;
}>

interface DocumentViewProps extends SnackbarProps{}

const PageItem:FunctionComponent<PageItemProps> = ({index , pagesNumber, scale}):ReactElement=>{
    return (
     <>
        {index !== 0 && (<div className={classes.separator}></div>)}
          <Page scale={scale} pageNumber={index + 1} renderForms={true} renderAnnotationLayer={true} renderTextLayer={true} className={classes.page_item}/>
        {index !== (pagesNumber - 1) && (<div className={classes.separator}></div>)}
     </>
    )
 }

 const DownLoadIcon:FunctionComponent = ():ReactElement<ReactSVGElement>=>{
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M4.79185 17.083C4.44667 17.0829 4.16667 17.3626 4.16667 17.7078C4.16667 18.053 4.44631 18.3329 4.79148 18.333L15.6248 18.3361C15.97 18.3362 16.25 18.0565 16.25 17.7113C16.25 17.3661 15.9704 17.0862 15.6252 17.0861L4.79185 17.083ZM10.2931 1.67612L10.2083 1.67041C9.89192 1.67041 9.63042 1.90554 9.58904 2.2106L9.58333 2.29541L9.58417 13.6996L6.48265 10.5993C6.26074 10.3774 5.91352 10.3573 5.66886 10.5389L5.59877 10.5994C5.3769 10.8213 5.35677 11.1685 5.53834 11.4132L5.59886 11.4833L9.76357 15.6471C9.98535 15.8689 10.3323 15.8891 10.577 15.7078L10.6471 15.6474L14.8166 11.4835C15.0609 11.2396 15.0612 10.8439 14.8172 10.5996C14.5955 10.3776 14.2483 10.3572 14.0035 10.5386L13.9334 10.599L10.8342 13.6937L10.8333 2.29541C10.8333 1.979 10.5982 1.7175 10.2931 1.67612L10.2083 1.67041L10.2931 1.67612Z" fill="white"/>
       </svg>
    )
 }

 const ErrorMessage:FunctionComponent<ErrorMessageProps> = (props):ReactElement=>{
    const {loading , message , reloadHandler} = props

   if(loading){
     return <div className={classes.loader_container}>
              <Loader />
            </div>
   }

   if(message){
     return <div className={classes.error_container}>
                <Typography><strong>Error message</strong>: {message}</Typography>
                <Button onClick={reloadHandler} variant='contained' color='secondary'>{Labels.Retry}</Button>
            </div>
   }

   return  props.children as ReactElement
   
 }



 const DocumentView:FunctionComponent<DocumentViewProps> = ({showSnackbar}):ReactElement=>{
    const {documentId} = useParams<{documentId:string}>();
    const [pages , setPages] = useState<number>(0);
    const [scalingPage , setScalingPage] = useState<number>(2);
    const [pdfHeight , setPdfHeight] = useState<number | string>('100%');
    const [loading , setLoading] = useState<boolean>(true);
    const [downloading , setDownloading] = useState<boolean>(false);
    const [token , setToken] = useState<string>('');
    const [error , setError] = useState<string>('');
    const {footer_height, header_height} = useSelector((state:RootState)=> state.dimensions);
    const {signedTimestamp , status} = useSelector<RootState, DseState>((state:RootState) => state.dse);
    const scalePageValue = useMediaQueryHook();
    const history = useHistory();

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        setPages(numPages)
    };

    const onDocumentLoadError = (error:Error):void=>{
        console.log(error)
        setError(Labels.UnableDisplayDocument)
    }

    const onDocumentLoadProgress = (data:OnLoadProgressArgs):void=>{}

    
    const renderPages = (scale:number):ReactElement[]=>{
        return new Array(pages).fill('page', 0, pages).map((each:string , index:number)=>{
           return <PageItem index={index} scale={scale} key={each + index} pagesNumber={pages} />
        })
    }

    const decodeDocumentId = (id:string):string=>{
        const decoder = new TextDecoder()
        const decoded = decodeURIComponent(id)
        const uint8Array:string[] = decoded.split(',')
        const binaryArray = new Uint8Array(uint8Array.map( each => +each))
        const docId = decoder.decode(binaryArray)
        return docId;
    }

    const getFileToken = async ()=>{
        try{
          const token = await AuthService.issueFileToken(decodeDocumentId(documentId));
          setToken(token);
        }catch(error:any){
          console.error(error);
          setError(getErrorMessage(error));
        }finally{
           setLoading(false);
        }
     }

    useEffect(()=>{
      getFileToken();
    }, [documentId]);

    useEffect(()=>{
      setScalingPage(scalePageValue);
    }, [scalePageValue])

    useEffect(()=>{
     if(footer_height && header_height){
       // 154 - download_block height (general) , 88 - arrow_back section height, 93 - margin-bottom in .container: dynamic calculate pdf height
       const documentHeight = window.innerHeight - ( 154 + footer_height + header_height + 93 + 88)
       setPdfHeight(documentHeight);
     }

    }, [footer_height, header_height])

    const backHandler = ()=>{
      history.goBack();
    }

    const downloadHandler = async (e:MouseEvent<HTMLButtonElement>)=>{
        if(!pages || downloading){
          return;
        }
        try{
          setDownloading(true);
          const {data} = await SharepointService.getFileAsync(token);
          const url = URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download' , 'dse.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
          URL.revokeObjectURL(url);
        }catch(error){
           console.log(error);
           showSnackbar(Labels.DocumentLoadFailed, 'top' , 'center')
        }finally{
          setDownloading(false);
        }

    }

    const retryHandler = ()=>{
        setLoading(true);
        setError('');
        if(token){
          setToken('');
        }
        getFileToken();
    }


   return (
    <div className={classes.wrapper}>
      <Header />
        <div className={classes.container}>
             <div className={classes.move_back}>
                <div className={classes.back_arrow}>
                    <div className={classes.back_icon} onClick={backHandler}>
                       <ArrowBack />
                    </div>
                    <span>Zurück</span>
                </div>
            </div>
            <div className={classes.download_block}>
                <div className={classes.download_wrapper}>
                    <div className={classes.data_protection}>
                        Datenschutzerklärung
                    </div>
                    <div className={classes.download}>
                        <Button disabled={!pages} className={`${classes.download_button} ${!pages && classes.disabled}`} onClick={downloadHandler}>
                            <div className={classes.download_icon}>
                                <DownLoadIcon />
                            </div>
                            <span>Download</span> 
                        </Button>
                        <div className={classes.date}>
                          { (status === Statuses.Signed && signedTimestamp) ? signedTimestamp : '' }
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.document_block} >  
            <ErrorMessage loading={loading} message={error} reloadHandler={retryHandler}>
               { token && 
               <Document
                    className={['react-pdf__Document' , classes.pdf_document]}
                    renderMode='canvas'
                    file={`${SHAREPOINT_SERVICE_URI}/api/sharepoint-library/file/${token}`}
                    loading={
                        <div className={classes.load_progress}>
                            <div className={classes.loading_pdf}>Loading PDF ...</div>
                            <LoadProgress />
                        </div>
                    }
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onDocumentLoadError}
                    onLoadProgress={onDocumentLoadProgress}
                >
                    {pages && renderPages(scalingPage)}
               </Document>}
            </ErrorMessage>
            </div>
        </div>
        {downloading && <Overlay />}
      <Footer />
    </div>
   
   )
}

export default withSnackbar(DocumentView);
