import rootReducer from '../reducers';
import { createStore } from 'redux';

let enhancer: any;
// TODO: Change on reactappenv
if (process.env.NODE_ENV === 'development') {
  const devToolsEnhancer = require('redux-devtools-extension').devToolsEnhancer;
  enhancer = devToolsEnhancer({});
}
const configureStore = (initialState?: any) => createStore(rootReducer, initialState, enhancer);

export default configureStore();
