import React, { FunctionComponent, ReactElement } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { LoginFormType } from './login-form-state';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';


export const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2, 0, 0)
  }
}));

interface FormSelectorProps {
  onSelect(formState: LoginFormType): void;
}

const FormSelector: FunctionComponent<FormSelectorProps> = (props: FormSelectorProps): ReactElement => {
  const classes = useStyles();
  const phoneLoginHandler = (): void => {
    props.onSelect(LoginFormType.phone);
  };

  const emailLoginHandler = (): void => {
    props.onSelect(LoginFormType.email);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Button variant='contained' fullWidth onClick={phoneLoginHandler} startIcon={<PhoneIphoneIcon />} className={classes.button}>
            Login mit Telefonnummer
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained' fullWidth onClick={emailLoginHandler} startIcon={<EmailIcon />} className={classes.button}>
            Login mit E-Mail
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FormSelector;
