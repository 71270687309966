import React, { FunctionComponent , ReactElement } from 'react';
import { isPdfExtension } from '../../../unit/constants';
import ImageIcon from '@material-ui/icons/Image';
import { Avatar, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, IconButton, Card, Box, CardContent, Typography} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface DocumentProps {
  file: File;
  deleteHandler: (fileName:string)=>void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    file_name:{
      fontFamily: 'Roboto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      maxWidth: 460,
      "& :first-child":{
        fontWeight: 300,
        fontSize: '1rem',
        lineHeight: 1.2,
        color: '#000000',
      },
      '& :last-child':{
        fontWeight: 300,
        fontSize: '0.875rem',
        lineHeight: 1,
        color: '#828282'
      },
      '@media (max-width: 600px)':{
        "& :first-child":{
          fontSize: '0.8rem!important'
        },
        '& :last-child':{
          fontSize: '0.7rem!important'
        }
      }
    },
    card:{
      width: '100%',
      boxShadow: 'none',
      border: 'none',
      "& > *":{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0px!important',
        padding: '0px!important',
      }
    },
    delete:{
       dispaly: 'flex',
       flexGrow:1,     
       '& div':{
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'flex-end',
         padding: "3px",
         "&:hover":{
           cursor: 'pointer'
         }
       }
    }
  })
);

const PdfIcon = ():ReactElement=>{
  return(
    <svg width="100%" height="100%" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
     <rect width="48" height="48" rx="24" fill="#828282"/>
     <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7531 25.169C18.431 25.169 18.1698 25.4302 18.1698 25.7523V29.2523C18.1698 29.5745 18.431 29.8357 18.7531 29.8357C19.0753 29.8357 19.3365 29.5745 19.3365 29.2523V28.6695H19.9167C20.8833 28.6695 21.6669 27.8859 21.6669 26.9192C21.6669 25.9526 20.8833 25.169 19.9167 25.169H18.7531ZM19.9167 27.5028H19.3365V26.3357H19.9167C20.239 26.3357 20.5003 26.5969 20.5003 26.9192C20.5003 27.2415 20.239 27.5028 19.9167 27.5028Z" fill="white"/>
     <path d="M27.4973 25.7516C27.4977 25.4297 27.7587 25.169 28.0806 25.169H29.8365C30.1586 25.169 30.4198 25.4302 30.4198 25.7523C30.4198 26.0745 30.1586 26.3357 29.8365 26.3357H28.6632L28.6617 27.5043H29.8365C30.1586 27.5043 30.4198 27.7655 30.4198 28.0877C30.4198 28.4098 30.1586 28.671 29.8365 28.671H28.6625L28.6639 29.2509C28.6647 29.573 28.4042 29.8349 28.0821 29.8357C27.7599 29.8365 27.4981 29.576 27.4973 29.2538L27.4943 28.0892L27.4943 28.0869L27.4973 25.7516Z" fill="white"/>
     <path fill-rule="evenodd" clip-rule="evenodd" d="M23.4167 25.169C23.0945 25.169 22.8333 25.4302 22.8333 25.7523V29.2523C22.8333 29.5745 23.0945 29.8357 23.4167 29.8357H23.9971C25.2858 29.8357 26.3305 28.791 26.3305 27.5024C26.3305 26.2137 25.2858 25.169 23.9971 25.169H23.4167ZM24 28.669V26.3357C24.643 26.3372 25.1638 26.859 25.1638 27.5023C25.1638 28.1457 24.643 28.6675 24 28.669Z" fill="white"/>
     <path fill-rule="evenodd" clip-rule="evenodd" d="M24 19.3333V12.3333H17C15.7113 12.3333 14.6667 13.3779 14.6667 14.6666V22.4464C13.9769 22.7741 13.5 23.4771 13.5 24.2916V30.7083C13.5 31.5227 13.9769 32.2258 14.6667 32.5534V33.3333C14.6667 34.6219 15.7113 35.6666 17 35.6666H31C32.2887 35.6666 33.3333 34.6219 33.3333 33.3333V32.5534C34.0231 32.2258 34.5 31.5227 34.5 30.7083V24.2916C34.5 23.4771 34.0231 22.7741 33.3333 22.4464V21.6666H26.3333C25.0447 21.6666 24 20.6219 24 19.3333ZM15.5417 23.9999C15.3806 23.9999 15.25 24.1305 15.25 24.2916V30.7083C15.25 30.8693 15.3806 30.9999 15.5417 30.9999H32.4583C32.6194 30.9999 32.75 30.8693 32.75 30.7083V24.2916C32.75 24.1305 32.6194 23.9999 32.4583 23.9999H15.5417Z" fill="white"/>
     <path d="M25.75 19.3333V12.9166L32.75 19.9166H26.3333C26.0112 19.9166 25.75 19.6554 25.75 19.3333Z" fill="white"/>
   </svg>
  )
}

const DeleteIcon =():ReactElement=>{
  return (
    <svg width="19" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 5C19.5 5.51284 19.114 5.93551 18.6166 5.99327L18.5 6H17.6553L16.4239 18.5192C16.2854 19.9269 15.1016 21 13.6871 21H6.31293C4.8984 21 3.7146 19.9269 3.57614 18.5192L2.34474 6H1.5C0.947715 6 0.5 5.55228 0.5 5C0.5 4.44772 0.947715 4 1.5 4H6.5C6.5 2.067 8.067 0.5 10 0.5C11.933 0.5 13.5 2.067 13.5 4H18.5C19.0523 4 19.5 4.44772 19.5 5ZM12.25 8.25C11.8703 8.25 11.5565 8.53215 11.5068 8.89823L11.5 9V16L11.5068 16.1018C11.5565 16.4678 11.8703 16.75 12.25 16.75C12.6297 16.75 12.9435 16.4678 12.9932 16.1018L13 16V9L12.9932 8.89823C12.9435 8.53215 12.6297 8.25 12.25 8.25ZM7.75 8.25C7.3703 8.25 7.05651 8.53215 7.00685 8.89823L7 9V16L7.00685 16.1018C7.05651 16.4678 7.3703 16.75 7.75 16.75C8.1297 16.75 8.44349 16.4678 8.49315 16.1018L8.5 16V9L8.49315 8.89823C8.44349 8.53215 8.1297 8.25 7.75 8.25ZM10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H11.5C11.5 3.17157 10.8284 2.5 10 2.5Z" fill="#212121"/>
   </svg>
  )
}

const Document: FunctionComponent<DocumentProps> = (props: DocumentProps): JSX.Element => {
   const classes = useStyles()

  const renderIcon = (fileName: string): JSX.Element => {
    if (isPdfExtension(fileName)) {
      return <PdfIcon />;
    }

    return <ImageIcon />
  };

  const sizeFormat = (size:number):string=>{
    if(size > 1024 * 1024 * 1024){
      return (size/(1024 * 1024 * 1024)).toFixed(2)+' Gb'
    }
    if(size > 1024 * 1024){
        return (size/(1024 * 1024)).toFixed(2)+' Mb'
    }
    if(size > 1024){
      return (size/(1024)).toFixed(2)+' Kb'
    }
    return size+' B'
  }

  return (
    <ListItem>
      <Card className={classes.card}>
        <CardContent>
          <ListItemAvatar>
            <Avatar>{renderIcon(props.file.name)}</Avatar>
          </ListItemAvatar>
          <ListItemText className={classes.file_name}>
              <Typography noWrap >{props.file.name}</Typography>
              <Typography noWrap >{sizeFormat(props.file.size)}</Typography>
          </ListItemText>
          <div className={classes.delete}>
            <div onClick={()=> props.deleteHandler(props.file.name)}>
              <DeleteIcon />
            </div>
          </div>
        </CardContent>
      </Card>
    </ListItem>
  );
};

export default Document;