import React, { useEffect, useRef } from 'react';
import useDndUpload from '../../hooks/dnd-upload';
import { Box, Paper, Typography } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import classes from './dnd-area.module.scss';

interface DnDAreaProps {
  onFileListChange(files: FileList): void;
}
const DndArea = (props: DnDAreaProps) => {
  const dndRef = useRef(null);
  const files = useDndUpload(dndRef);

  useEffect(() => {
    if (files?.length) {
      props.onFileListChange(files);
    }
  }, [files]);

  return (
    <Paper elevation={3} className={classes.container}>
      <Box boxShadow={3}>
        <label htmlFor='modal-dnd-upload' className={classes.dnd_area} ref={dndRef}>
          <Typography className={classes.label} variant='h5' component='h5'>
            <SaveAltIcon className={classes.icon} />
            <div className={classes.text}>Zum Hochladen hier klicken </div>
          </Typography>
          <input type='file' id='modal-dnd-upload' multiple={true} className='d-none' />
        </label>
      </Box>
    </Paper>
  );
};

export default DndArea;
