import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: '#FFFFFF',
    padding: theme.spacing(1.5, 0, 1.5),
    background: '#000000',
    borderRadius: '5px',
    '&:hover':{
      color: '#FFFFFF',
      background: '#000000',
    }
  },
  back_button: {
    color: '#0067b8'
  },
  mail_spacing:{
    margin: theme.spacing(4, 0, 3)
  },
  code_spacing:{
    margin: theme.spacing(3, 0, 2)
  },
  input:{
    background: '#FCFCFC',
    borderRadius: '4px',
    '& input:valid:focus + fieldset':{
      border: '1px solid #000000'
    }
  },
  label:{
    display: 'flex',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    marginLeft: '0.3rem',
    letterSpacing: '0.005em',
    '& > div:nth-child(2)':{
      color: '#D1050C'
    }
  },
  code_message:{
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    letterSpacing: '0.005em',
    color: '#828282'
  },
  accounts_container:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
  },
  email_view:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap:10,
    alignSelf:'flex-start',
    marginBottom: 30,
    '& span:first-child':{
      color: '#706D6D',
      fontFamily: 'Roboto',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 500,
    },
    '& span:last-child':{
      color: '#000',
      fontFamily: 'Open Sans',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 600
    }
  },
  account_card:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: 20,
    marginBottom: 20,
    gap:70,
    borderRadius: '4px',
    background: '#FFF',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '&:last-child':{
      marginBottom: '0px!important',
    },
    '@media(max-width: 1000px)':{
      gap:50
    },
    '@media(max-width: 620px)':{
      gap:30
    },
    '@media(max-width: 330px)':{
      gap:15
    },
    '@media(max-width: 300px)':{
      paddingLeft:3
    },
  },
  checkbox:{},
  text_presentation:{
   display:'flex',
   flexDirection:'column',
   gap:5,
  },
  column_1:{
    color: '#706D6D',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400
  },
  column_2:{
    color: '#102F3F',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600
  }
}));

export const useLoginStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  back: {
    top: 0,
    margin: theme.spacing(1, 3),
    position: 'absolute',
    '&:hover':{
      backgroundColor: '#ffffff'
    }
  },
  image: {
    backgroundImage: 'url(illustration.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(14, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    width: '68px',
    height: '68px',
    margin: theme.spacing(1),
    backgroundColor: '#b61c22',
  },
  title:{
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '2rem',
    lineHeight: '2.375rem',
    color: '#000000',
  },
  progress: {
    position: 'fixed',
    top: 0,
    width: '100%'
  }
}));
