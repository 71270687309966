import React, {FC, ReactElement } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import classes from './order-info.module.scss';
import { SalesOrder } from '../../../services/sales-order-service';
import { toGermanDateString } from '../../../unit/constants';
import { Button , Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface UploadButtonProps{
  handleUpload: ()=>void,
  media:'lg' | 'sm'
}

const UploadIcon = ():ReactElement=>{
  return(
    <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M12.125 16.0834H0.87694C0.531761 16.0834 0.25194 16.3632 0.25194 16.7084C0.25194 17.0248 0.487068 17.2863 0.79213 17.3277L0.87694 17.3334H12.125C12.4702 17.3334 12.75 17.0536 12.75 16.7084C12.75 16.392 12.5149 16.1305 12.2098 16.0891L12.125 16.0834H0.87694H12.125ZM11.2559 5.07461L7.09116 0.910765C6.79089 0.61056 6.31851 0.587337 5.99162 0.841188L5.91311 0.910423L1.74357 5.07427C1.41791 5.39948 1.41755 5.92712 1.74277 6.25278C2.04296 6.55339 2.51564 6.57682 2.84272 6.32285L2.92128 6.25358L5.6675 3.51091L5.66764 14.0001C5.66767 14.4275 5.9894 14.7797 6.40385 14.8278L6.50104 14.8334C6.9284 14.8334 7.2806 14.5117 7.3287 14.0972L7.3343 14L7.33333 3.50925L10.0775 6.25324C10.3779 6.55361 10.8506 6.57667 11.1775 6.32245L11.256 6.25312C11.5564 5.95269 11.5794 5.47999 11.3252 5.15311L11.2559 5.07461L7.09116 0.910765L11.2559 5.07461Z" fill="white"/>
   </svg>
  )
}

const UploadButton:FC<UploadButtonProps>= ({handleUpload, media}):ReactElement=>{
   return (
    <Button className={ media === 'lg' ? classes.upload_btn_lg : classes.upload_btn_sm} onClick={handleUpload}>
       <UploadIcon/><span>hochladen</span>
    </Button>
   )
}

const OrderInfo = ({ onUpload, order }: { onUpload(): void; order: SalesOrder }) => {

  return (
    <div className={classes.container}>
      <div className={classes.wrapper_container}>
        <Card className={classes.info}>
            <CardContent className={classes.card_content} >
              <div className={classes.manufacturer}>{order.manufacturer}</div>
              <Typography noWrap className={classes.order_type}>{order.type}</Typography>
              <div className={classes.order_name}>{order.name}</div>
            </CardContent>
        </Card>
        <section className={classes.upload}>
           < UploadButton handleUpload={onUpload} media='lg'/>
           <div className={classes.created}>{toGermanDateString(order.createdOn)}</div>
        </section>
      </div>
      < UploadButton handleUpload={onUpload} media='sm'/>
    </div>
  );
};

export const OrderSkeleton = (): ReactElement => {
  return (
    <div className={classes.container}>
      <div className={classes.wrapper_container}>
        <div className={classes.info} style={{width:'50%'}}>
          <h1 style={{ fontSize: '20px', lineHeight: '40px' }}>
            <Skeleton variant='text' />
          </h1>
          <div style={{width: '80%' ,lineHeight: '65px'}}>
            <Skeleton variant='text' />
          </div>
          <div style={{width: '45%' , lineHeight: '30px'}}>
            <Skeleton variant='text' />
          </div>
        </div>
        <div className={classes.upload} style={{width: '50%'}}>
          <div style={{width: '70%' , lineHeight: '50px'}}>
            <Skeleton variant='text' />
          </div>
          <div style={{width: '45%' , lineHeight: '30px'}}>
            <Skeleton variant='text' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderInfo;
