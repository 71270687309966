import React, { useEffect, useState, FunctionComponent, ReactElement, ReactSVGElement } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Header from '../header';
import Footer from '../footer';
import { CircularProgress, Container } from '@material-ui/core';
import DseCard from '../dse-card/dse-card';
import { useDispatch , useSelector} from 'react-redux';
import {RootState} from '../../reducers';
import { SET_ORDERS } from '../../unit/reducerTypes';
import { toGermanDateString } from '../../unit/constants';
import {useError} from '../../hooks/useError';
import {getErrorMessage} from '../../helpers';
import {AxiosResponse} from 'axios';
import salesOrderService ,{Dse_AND_OrdersResponse , DseResponse, SalesOrder} from '../../services/sales-order-service';
import {SalesOrderUpdateRootI , DseUpdateI} from '../../signalR-events/signalR-events-interfaces';
import FileHubMethod from '../../signalR-events/signalR-events-instanse';
import {SignalRState} from '../../reducers/signalR';
import {DseCreatedRequestStatus} from '../../reducers/dse-created-message';
import store from '../../store';
import {setDseInfo , updateOrder, updateDseInfo, sendDsePreRequestMessage} from '../../actions';
import {DseState} from '../../reducers/dse';
import {OrderState , InitialOrdersState} from '../../reducers/orders';
import {Statuses} from '../../unit/constants';
import SalesOrderService from '../../services/sales-order-service';
import classes from './app.module.scss';

const AvailableForSigning:FunctionComponent = ():ReactElement<ReactSVGElement>=>{
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.70835 13.7498C6.6179 13.7498 6.1932 14.2352 5.87059 15.3117L5.81408 15.5094C5.66199 16.0571 5.57106 16.2212 5.38687 16.2971C5.17094 16.3861 5.02698 16.3734 4.80068 16.2571L4.67824 16.1885C4.6342 16.162 4.58618 16.1315 4.53188 16.0956L3.9792 15.7195C3.46596 15.3819 3.00479 15.1588 2.44327 15.0184C2.1084 14.9347 1.76907 15.1383 1.68535 15.4732C1.60163 15.8081 1.80523 16.1474 2.1401 16.2311C2.47931 16.3159 2.77193 16.4443 3.09453 16.6389L3.34336 16.7979L3.74254 17.0713C3.93402 17.2015 4.07857 17.2914 4.22916 17.3688C4.76489 17.6442 5.28736 17.6901 5.86317 17.4528C6.48384 17.197 6.73416 16.8029 6.96842 16.0181L7.09828 15.5666C7.2494 15.0755 7.3524 14.9998 7.70835 14.9998C7.96617 14.9998 8.23301 15.1287 8.59867 15.4356L8.75262 15.5697L9.52002 16.2991C10.6935 17.4021 11.6939 17.9377 13.1262 17.9377C14.5314 17.9377 15.7467 17.726 16.7669 17.297L14.3106 16.6268C13.9379 16.6673 13.5431 16.6877 13.1262 16.6877C12.1405 16.6877 11.433 16.3419 10.5543 15.5522L10.3391 15.3533L9.89594 14.9273C9.69872 14.7383 9.55096 14.6029 9.40218 14.478C8.83252 14 8.31475 13.7498 7.70835 13.7498ZM4.14141 2.47449C2.97686 3.63904 2.97686 5.52715 4.14141 6.69171L4.38157 6.9323C3.42201 8.10009 3.45669 9.33771 4.3491 10.2327L6.0168 11.9004C6.26109 12.1443 6.65682 12.1439 6.90068 11.8997C7.14455 11.6554 7.1442 11.2596 6.89991 11.0158L5.23427 9.35013C4.83009 8.94477 4.78336 8.44151 5.26745 7.81817L12.4484 14.9986C12.6791 15.2294 12.966 15.3959 13.2808 15.4818L17.5439 16.6444C18.0105 16.7717 18.4386 16.3435 18.3113 15.877L17.1487 11.6139C17.0628 11.2991 16.8963 11.0122 16.6656 10.7814L8.35863 2.47449C7.19408 1.30994 5.30597 1.30994 4.14141 2.47449Z" fill="#D1050C"/>
    </svg>
  )
}

const App: FunctionComponent = (): ReactElement => {
  const [orders, setOrders] = useState<SalesOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const {connected , SignalRHub } = useSelector<RootState,SignalRState>( (state:RootState)=> state.signal_R);
  const dseInfo = useSelector<RootState,DseState>((state:RootState) => state.dse);
  const { sent } = useSelector<RootState, DseCreatedRequestStatus>((state:RootState) => state.sendDseCreatedMessage);
  const {link , status, recordId} = dseInfo;
  const navigateToErrorPage = useError();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(()=>{
    const dseRequest = async ()=>{
      if(link && recordId){
        try{
          await SalesOrderService.dsePreRequest(recordId);
          dispatch(sendDsePreRequestMessage());
        }catch(error:unknown){
          console.log(error);
          catchError(getErrorMessage((error as AxiosResponse)));
        }
        
      }
    }
    const resolved = typeof status === 'number';
    const notAvailable = resolved && status === Statuses.NotAvailable;
    const signed = resolved && status === Statuses.Signed;
    
    if( !resolved || (notAvailable || signed) || sent){
      return;
    }
  
    dseRequest();

  }, [status]);


  useEffect(() => {
    salesOrderService
      .getAll()
      .then(result => {
        const {dse , records}:Dse_AND_OrdersResponse<DseResponse, SalesOrder> = result.data
        dispatch({
          type: SET_ORDERS,
          payload: records.length ? records.reduce<{ [key: string]: SalesOrder }>((prev:{ [key: string]: SalesOrder }, current:SalesOrder) => {
            prev[current.id] = current;
            return prev;
          }, {}) : []
        });
        dispatch(setDseInfo(dse));
        setOrders(records);
        setLoading(false);
      })
      .catch( (error:unknown) =>{
         console.log(error)
         catchError(getErrorMessage((error as AxiosResponse)))
        }
      )
  }, [dispatch]);


  const salesOrderUpdateRootHandler = (message:SalesOrderUpdateRootI)=>{
    const allOrders:InitialOrdersState = store.getState().orders;
    const order:SalesOrder | undefined = allOrders[message.recordId];
    if(order && order.entityName === message.entityName){
      dispatch(updateOrder(order.id , message.envelopeAvailable));
      setOrders(orders.map( order => {
        if(order.id !== message.recordId){
           return order
        }
        return {...order,  envelopeAvailable:message.envelopeAvailable}
      }));
    }
  }

  const dseUpdateHandler = (message:DseUpdateI)=>{
    dispatch(updateDseInfo(message))
  }

  useEffect(()=>{
    if(connected && SignalRHub && !loading){
       SignalRHub.on(FileHubMethod.RecordEnvelopeUpdateRoot.toString(), salesOrderUpdateRootHandler);
       SignalRHub.on(FileHubMethod.DSEEnvelopeUpdate.toString(), dseUpdateHandler);

     return (): void => {
      SignalRHub.off(FileHubMethod.RecordEnvelopeUpdateRoot.toString());
      SignalRHub.off(FileHubMethod.DSEEnvelopeUpdate.toString());
     };
    }  
  },[connected, SignalRHub, loading])

  const catchError = (error:string)=>{
      setLoading(false);
      navigateToErrorPage({from:'/', error });
  }

  
  return (
    <div className={classes.wrapper}>
      <Header />
      <Container className={classes.container}>
        <Grid container spacing={4}>
          {loading && (
            <Grid item sm={12} md={12} xs={12} className='text-center'>
              <CircularProgress color='primary' />
            </Grid>
          )}
          {( !loading ) &&
            (
             < DseCard />
            )
          }
          {( !loading && Boolean(orders.length)) && 
           (orders.map(order => {
            return (
              <Grid item sm={6} md={4} xs={12} key={order.id}>
                <Card className={classes.card} onClick={() => history.push(`/order/${order.id}`,{entityName: order.entityName})}>
                  <CardActionArea className={classes.card_area}>
                    <CardContent>
                      <Typography className={classes.card_type}>
                        <div>{order.manufacturer}</div>
                        <div>{order.envelopeAvailable ? <AvailableForSigning /> : ''}</div>
                      </Typography>
                      <Typography noWrap gutterBottom className={classes.manufacturer}>
                        {order.type}
                      </Typography>
                      <Typography className={classes.order_info}>
                          <div>{order.name}</div>
                          <div>{toGermanDateString(order.createdOn)}</div>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
           }))
          }
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default App;
