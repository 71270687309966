import React, {useEffect, FunctionComponent } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure } from 'react-use';
import { logout , setHeaderHeight, setDisconnected } from '../../actions';
import { RootState } from '../../reducers';
import {SignalRState} from '../../reducers/signalR';
import styles from './header.module.scss';

const Logout = ()=>{
  return(
    <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M16 1.4003V2.43361L18.7924 2.43364C19.3992 2.43364 19.901 2.88406 19.9812 3.46902L19.9924 3.63165L20.0032 10.4336H17.6032L17.5944 4.83364L16 4.83361L16.0009 12.0415L17.6032 12.04L17.6048 12.0336H20.0064L20.0048 12.04H27.9072L25.1513 9.28202C24.7254 8.85592 24.6868 8.18925 25.0354 7.71953L25.1516 7.58496C25.5777 7.15901 26.2444 7.12039 26.7141 7.46904L26.8487 7.58525L31.6433 12.3815C32.0688 12.8072 32.1078 13.4731 31.76 13.9428L31.6441 14.0774L26.8495 18.8829C26.3814 19.3521 25.6216 19.353 25.1524 18.8849C24.7259 18.4593 24.6864 17.7927 25.0345 17.3225L25.1505 17.1878L27.8912 14.44L16.0009 14.4415L16 21.6352L17.6223 21.636L17.6112 16.0336H20.0128L20.0242 22.834C20.0252 23.4422 19.5736 23.9453 18.9873 24.025L18.8242 24.036L16 24.0352V25.2336C16 25.9799 15.3261 26.5451 14.5913 26.4153L0.991284 24.0133C0.417935 23.912 0 23.4138 0 22.8316V3.63364C0 3.04595 0.425623 2.54473 1.00555 2.4495L14.6055 0.216162C15.3361 0.0961883 16 0.659933 16 1.4003ZM10.4034 12.8336C9.51788 12.8336 8.8 13.5515 8.8 14.4371C8.8 15.3226 9.51788 16.0405 10.4034 16.0405C11.289 16.0405 12.0069 15.3226 12.0069 14.4371C12.0069 13.5515 11.289 12.8336 10.4034 12.8336Z" fill="white"/>
    </svg>
  )
}

const Logo = ()=>{
  return (
      <svg className={styles.logo} width="88" height="26" viewBox="0 0 88 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 25V1H6.5V20.4H14.7V25H0Z" fill="white"/>
        <path d="M36.6 13.7C36.6 17.5 36.5 18.5 36.3 19.4C35.2 23.3 31.2 25.6 25.7 25.6C21.5 25.6 18.5 24.3 17.1 21.8C16.2 20.1 16.1 19.3 16.1 14.2V1H22.6V14.5C22.6 18 22.6 18.3 23 19.2C23.5 20.4 24.7 21.1 26.3 21.1C27.7 21.1 28.9 20.5 29.5 19.5C30.2 18.5 30.3 18 30.3 14.5V1H36.6V13.7Z" fill="white"/>
        <path d="M55.3 5.5H46.7V10.6H54.6V15H46.7V20.4H55.8V25H40.3V1H55.3V5.5Z" fill="white"/>
        <path d="M87.5 22.1C87.5 24.1 85.9 25.7 84 25.7C82.1 25.7 80.4 24.1 80.4 22.2C80.4 20.3 82.1 18.6 83.9 18.6C86 18.6 87.5 20.1 87.5 22.1Z" fill="#D1050C"/>
        <path d="M76 6.39998C73.8 5.49998 71.9 5.09998 70 5.09998C66.1 5.09998 64.2 7.39998 64.2 12.8C64.2 17.7 66.1 20.9 69.1 20.9C70.3 20.9 71.1 20.7 72.2 20.1V12.5H78.2V22.1C74.8 24.4 71.3 25.6 68.2 25.6C61.5 25.6 57.6 21 57.6 13C57.6 4.79998 61.8 0.599976 69.6 0.599976C72.2 0.599976 74.5 0.899976 77.2 1.69998L76 6.39998Z" fill="white"/>
      </svg>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offset: theme.mixins.toolbar
  })
);

const Header: FunctionComponent = () => {
  const [ref , {height}] = useMeasure<HTMLDivElement>()
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector<RootState>(state => state.auth.isAuthenticated);
  const {connected , SignalRHub } = useSelector<RootState,SignalRState>( (state:RootState)=> state.signal_R);

  useEffect(()=>{
   if(!!height){
    dispatch(setHeaderHeight(height))
   }
  }, [height])

  const logoutHandler = async () => {
    const unsubscribe = async (): Promise<void> => {
      if (SignalRHub && connected) {
          await SignalRHub.stop();
      }
      dispatch(setDisconnected());
    };
    await unsubscribe();
    dispatch(logout());
  };

  return (
    <>
      <AppBar position='fixed' component={'div'} ref={ref} className={styles.app_bar}>
        <Toolbar className={styles.toolbar}>
          <Link to='/'>
            <Logo />
          </Link>
          <Typography className={styles.title}></Typography>
          {isAuthenticated && (
            <Button className={styles.logout_btn} onClick={logoutHandler}>
              <Logout />
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </>
  );
};

export default Header;
