import {useState, useEffect} from 'react';
import {useMediaQuery} from '@material-ui/core';

interface ScalingMeasureI{
    [key:string] :number
}
const scalingMeasure:ScalingMeasureI = {
  '0': 0.6,
  '1': 1,
  '2': 1,
  '3' : 1.5
}

export const useMediaQueryHook =():number=>{
  const[scaling , setScaling ] = useState<number>(2);
  const query_1630 = useMediaQuery('(max-width:1630px)');
  const query_1334 = useMediaQuery('(max-width:1334px)');
  const query_1038 = useMediaQuery('(max-width:1038px)');
  const query_860 = useMediaQuery('(max-width:860px)');

  useEffect(()=>{
   let key:number | null = null;
   const queries = [query_860, query_1038 , query_1334, query_1630];
   const matches = queries.some( (q:boolean, index:number)=>{
    key = index;
    return q;
   })

   if(typeof key === 'number' && matches){
    setScaling(scalingMeasure[String(key)]);
   }else{
    setScaling(2);
   }
   
  }, [query_1630, query_1334, query_1038, query_860]);

  return scaling;
}