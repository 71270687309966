import React, { useEffect, FunctionComponent, ReactElement } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, RouteProps } from 'react-router-dom';
import Login from '../components/login';
import App from '../components/app';
import { useSelector} from 'react-redux';
import { RootState } from '../reducers';
import Order from '../components/order';
import Document from '../components/document';
import {setConnection , setDisconnected} from '../actions';
import store from '../store';
import {SignalRState} from '../reducers/signalR';
import * as signalR from '@microsoft/signalr';
import {CUSTOMER_PORTAL_URI} from '../unit/url';
import ErrorContainer from '../components/error';


const Routes: FunctionComponent = (): ReactElement => {
  const { SignalRHub } = useSelector<RootState,SignalRState>( (state:RootState)=> state.signal_R);
  const {isAuthenticated , token} = useSelector((store:RootState) => store.auth); 

  const getLocationUrl = ():string=>{
     let url = ''
    if (process.env.NODE_ENV === 'development') { 
       url = window.location.origin
    }else{
       url = CUSTOMER_PORTAL_URI
    }
    return url;
  }

  
  useEffect(() => {
      if (!isAuthenticated && !token) {
        return;
      }

      const connect = async (): Promise<void> => {
        const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${getLocationUrl()}/hub`, {
          accessTokenFactory:() => token as string
        })
        .build()
        await connection.start();
        store.dispatch(setConnection(connection))
      };
      
      connect();
  
      return (): void => {
        const unsubscribe = async (): Promise<void> => {
          if (SignalRHub) {
              await SignalRHub.stop();
              store.dispatch(setDisconnected());
          }
        };
        unsubscribe();
      };

  }, [isAuthenticated, token]);
 
  return(
    <Router>
      <Switch>
        <PrivateRoute path='/' exact={true} component={App} />
        <PrivateRoute path='/order/:id' component={Order} />
        <PrivateRoute path='/document/pdf/:documentId' component={Document} />
        <Route path='/login' component={Login} />
        <Route path='/error' component={ErrorContainer}/>
        <PrivateRoute component={App} />
      </Switch>
    </Router>
  )
};

const PrivateRoute: FunctionComponent<RouteProps> = (props: RouteProps): JSX.Element => {
  const isAuthenticated = useSelector<RootState>(state => state.auth.isAuthenticated);
  return (
    <Route {...props}>
      {isAuthenticated === true ? (
        props.children
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )}
    </Route>
  );
};

export default Routes;
