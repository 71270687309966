import { AnyAction } from 'redux';
import { SET_ENVELOPES , CHANGE_ENVELOPE, DELETE_ENVELOPE} from '../unit/reducerTypes';
import {CustomerEnvelopes} from '../services/sales-order-service';

export interface CustomerEnvelopesState {
   envelopes:CustomerEnvelopes[]
}

const INITIAL_STATE:CustomerEnvelopesState = {
    envelopes:[]
};

const envelopesReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_ENVELOPES:
      return { ...state, envelopes:[...action.payload]};
    case CHANGE_ENVELOPE:
        return { ...state , envelopes:[...state.envelopes.map(envelope => {
          if(envelope.envelopeId === action.payload.envelopeId){
            return {
                ...envelope,
                ...action.payload.updatedEnvelope
            };
          }
          return envelope;
        })
        ]};
    case DELETE_ENVELOPE:
       return {...state , envelopes:[...state.envelopes.filter( envelope => envelope.envelopeId !== action.payload)]}
    default:
      return {...state};
  }
};

export default envelopesReducer;
