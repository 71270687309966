import { AnyAction } from 'redux';
import { SEND_PRE_REQUEST , DENY_PRE_REQUEST} from '../unit/reducerTypes';

export interface DseCreatedRequestStatus {
    sent:boolean;
}

const INITIAL_STATE: DseCreatedRequestStatus = {
    sent:false
};

const dseCreatedRequestStatusReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case SEND_PRE_REQUEST:
      return {...state , sent:true};
    case DENY_PRE_REQUEST:
      return {...state , sent:false};
    default:
      return state;
  }
};

export default dseCreatedRequestStatusReducer;