import { AnyAction } from 'redux';
import jwtDecode from 'jwt-decode';
import { LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGIN_USER_FAILURE, LOGOUT_USER } from '../unit/reducerTypes';

const INITIAL_STATE: AuthState = {
  token: null,
  userName: null,
  isAuthenticated: false,
  isAuthenticating: false,
  statusText: null
};

export interface AuthState {
  token: string | null;
  userName: string | null;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  statusText: string | null;
}

const authReducer = (state = INITIAL_STATE, action: AnyAction): AuthState => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return { ...state, isAuthenticating: true, statusText: null };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        token: action.payload.token,
        userName: (jwtDecode(action.payload.token as string) as any)['phone-number'],
        statusText: 'You have been successfully logged in.'
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        token: null,
        userName: null,
        statusText: `Authentication Error: ${action.payload.status} ${action.payload.statusText}`
      };
    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        userName: null,
        statusText: 'You have been successfully logged out.'
      };
    default:
      return state;
  }
};

export default authReducer;
