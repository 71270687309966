import React, {FunctionComponent , ReactElement} from 'react';
import {useLocation , useHistory} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container, Typography, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Header from '../header';
import Footer from '../footer';
import * as Labels from '../../unit/labels';
import {ArrowBack} from '../login/login';
import classes from './error.module.scss';


 const ErrorContainer:FunctionComponent = ():ReactElement=>{
    const {state} = useLocation<{error:string, from:string}>()
    const history = useHistory()

    
    return (
       <React.Fragment>
        <Header />
          <Container className={classes.error_container} >
            <Box className={classes.box}>
              <div className={classes.back_section}>
                <Link className={classes.link} to={state.from}>
                  <ArrowBack />
                </Link>
                <Typography className={classes.back}>{Labels.Back}</Typography>
              </div>
              <Typography className={classes.error_title} variant='subtitle1'>Error message:</Typography>
              <Alert className={classes.error_message} severity='error'>{!!state?.error ? state.error : ''}</Alert>
            </Box>
          </Container>
        <Footer />
       </React.Fragment>
    )
}

export default ErrorContainer