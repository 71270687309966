import { useState, useEffect, RefObject } from 'react';

const useDnDUpload = (ref: RefObject<HTMLLabelElement>) => {
  const [file, setFile] = useState<FileList | null>(null);

  const changeHandler = (event: Event) => {
    const files = (event.target as HTMLInputElement).files;
    if (files?.length) {
      setFile(files);
    }
  };

  const dragEnterHandler = (event: Event) => {
    event.preventDefault();
    const target = event.target as HTMLDivElement;
    target.classList.add('drag_over');
  };

  const dragExitHandler = (event: Event) => {
    event.preventDefault();
    const target = event.target as HTMLDivElement;
    target.classList.remove('drag_over');
  };

  const dropHandler = (event: DragEvent) => {
    event.preventDefault();
    const target = event.target as HTMLDivElement;
    target.classList.remove('drag_over');
    const files = event?.dataTransfer?.files;
    if (files?.length) {
      setFile(files);
    }
  };

  const dragOverHandler = (event: Event) => {
    event.preventDefault();
  };

  useEffect(() => {
    ref?.current?.addEventListener('change', changeHandler);
    ref?.current?.addEventListener('dragenter', dragEnterHandler);
    ref.current?.addEventListener('dragover', dragOverHandler);
    ref.current?.addEventListener('dragleave', dragExitHandler);
    ref.current?.addEventListener('drop', dropHandler);
    return () => {
      ref?.current?.removeEventListener('change', changeHandler);
      ref?.current?.removeEventListener('dragenter', dragEnterHandler);
      ref?.current?.removeEventListener('dragover', dragOverHandler);
      ref?.current?.removeEventListener('dragleave', dragExitHandler);
      ref?.current?.removeEventListener('drop', dropHandler);
    };
  }, [ref]);

  return file;
};


export default useDnDUpload;