import axios, { AxiosResponse } from 'axios';
import { withBearer } from './with-bearer';
import { SHAREPOINT_SERVICE_URI } from '../unit/url';

export interface Document{
  availableForCustomer: boolean;
  canBeDeletedByCustomer: boolean;
  comment: boolean | null;
  createdBy: string;
  createdOn: string;
  documentSigned: boolean;
  documentType: string | null;
  envelopeJobID: string | null;
  envelopeLink: string | null;
  envelopeData: string | null;
  id: string;
  modifiedBy: string;
  modifiedOn: string;
  name: string;
  relativeUrl: string;
  requireSignature: boolean;
}

export interface UploadFileModel {
  name: string;
  bodyBase64: string;
  availableForCustomer: boolean;
  documentSigned: boolean;
  canBeDeletedByCustomer: boolean;
  //createdBy: string;
  //modifiedBy: string;
  comment: string;
  documentType: string;
  [key: string]: any;
}

export interface SharepointFile {
  id: string;
  relativeUrl: string;
  name: string;
  availableForCustomer: boolean;
  documentSigned: boolean;
  canBeDeletedByCustomer: boolean;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
  comment: string;
  documentType: string;
  [key: string]: boolean | string;
}

export interface UploadFileCommand {
  folderName: string;
  fileToUpload: UploadFileModel;
}

// TODO: Restrict use access
class SharepointService {
  private readonly _endpoint: string = `${SHAREPOINT_SERVICE_URI}/api/sharepoint-library`;

  public async fetchFiles(orderName: string, entityName:string): Promise<AxiosResponse<Document[]>> {
    return axios({
      method: 'GET',
      url: `${this._endpoint}/${orderName}/${entityName}`,
      headers: withBearer({})
    });
  }

  public async uploadFileAsync(file: UploadFileCommand): Promise<AxiosResponse<Document>> {
    return axios.post(`${this._endpoint}/upload-file`, file, {
      headers: withBearer({})
    });
  }

  public async deleteAsync(id: string): Promise<AxiosResponse<void>> {
    return axios.delete(`${this._endpoint}/delete/${id}`, {
      headers:  withBearer({})
    });
  }

  public async getFileAsync(token: string): Promise<AxiosResponse<any>> {
    
    const result = await axios.get(`${this._endpoint}/file/${token}`, {
        responseType: 'blob',
        headers: {
          Authorization: withBearer({})
        }
      });
      return result
      
  }

}

export default new SharepointService();
