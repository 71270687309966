import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

export type ErrorInfo = {
    from:string,
    error:string
}


export const useError = ():((info:ErrorInfo)=> void)=>{
     const history = useHistory()

     const navigateToErrorPage = useCallback((info:ErrorInfo)=>{
        const { from, error } = info
        history.push(
            `/error`, 
            {
              from,
              error
            }
        )
     }, [history])

     return navigateToErrorPage
}