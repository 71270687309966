import React, { FunctionComponent, ReactElement, useEffect, useState} from 'react';
import { Link, useRouteMatch , useLocation} from 'react-router-dom';
import Loader from '../loader';
import { ButtonGroup, Container, Button, Modal, Box, Typography } from '@material-ui/core';
import Documents from './documents';
import DocumentList from './document-list';
import { useSelector, useDispatch } from 'react-redux';
import OrderInfo, { OrderSkeleton } from './order-info';
import DndArea from '../dnd-area';
import Header from '../header';
import Footer from '../footer';
import sharepointService, { Document, UploadFileModel } from '../../services/sharepoint-service';
import salesOrderService, { SalesOrder, CustomerEnvelopes } from '../../services/sales-order-service';
import {CustomerEnvelopesState} from '../../reducers/customer-envelopes';
import { readFileAsBase64Async, Base64File, map, removeBase64Prefix, getExtension , getFileName } from '../../unit/constants';
import { RootState } from '../../reducers';
import { SET_ORDER, SET_CURRENT_ORDER } from '../../unit/reducerTypes';
import {useError} from '../../hooks/useError';
import {getErrorMessage} from '../../helpers';
import {SignalRState} from '../../reducers/signalR';
import FileHubMethod from '../../signalR-events/signalR-events-instanse';
import {SalesOrderUpdateI , SalesOrderCreateI} from '../../signalR-events/signalR-events-interfaces';
import {setEnvelopes , changeEnvelopes} from '../../actions';
import {DocumentsBelonging} from './envelope-list';
import store from '../../store';
import axios,  {AxiosError , CancelTokenSource} from 'axios';
import * as Labels from '../../unit/labels';
import classes from './order.module.scss';
import {ArrowBack} from '../login/login';


export type GrouppedEnvelope =  {
   [key:string]:Document[]
}

export type ReturnValue<G , D> = [G, D[]]

export interface DocumentsScope{
  grouped:GrouppedEnvelope,
  ungrouped: Document[]
}

interface LocationState{
  entityName:string
}


const Order: FunctionComponent = (): JSX.Element | ReactElement=> {
  const match = useRouteMatch<{ id: string }>();
  const {state} = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [documents, setDocuments] = useState<DocumentsScope>({grouped:{}, ungrouped:[]});
  const order = useSelector<RootState, SalesOrder | undefined>(state => state.orders[match.params.id]);
  const {envelopes} = useSelector<RootState, CustomerEnvelopesState>(state => state.envelopes);
  const {connected , SignalRHub } = useSelector<RootState,SignalRState>( (state:RootState)=> state.signal_R);
  const navigateToError = useError();
  const dispatch = useDispatch();
  const entity_name = (state as LocationState).entityName;

  const catchError = (from:string, error:string)=>{
    if(loading){
      setLoading(false)
    }
    navigateToError({from, error})
  }

  const groupByEnvelope = (files:Document[]):ReturnValue<GrouppedEnvelope, Document>=>{
    const noMatches:Document[] = []
    const envelopeMatched = files.reduce((prev:GrouppedEnvelope, current:Document):GrouppedEnvelope=>{
        const currentEnvelope = current.envelopeData

        if(currentEnvelope){
          if(prev[currentEnvelope]){
            prev[currentEnvelope].push(current)
          }else{
            prev[currentEnvelope] = []
            prev[currentEnvelope].push(current)
          }
        }else{
          noMatches.push(current)
        }

        return prev
    }, {})

    return [envelopeMatched , noMatches]
  }

  const replacementHandler = (envelopeId:string, type:DocumentsBelonging)=>{
    if(type === 'envelope-list'){
      const ungrouped = documents.grouped[envelopeId]
      setDocuments( prev => ({
         ...prev,
         ungrouped: [...ungrouped , ...prev.ungrouped]
      }))
    }
  }



  useEffect(() => {
    let source:CancelTokenSource = axios.CancelToken.source();

    const orderService = async ()=>{
      try{
        const response = await salesOrderService.getSalesOrder(match.params.id, entity_name, source.token);
        const {record , customerEnvelopes} = response.data;
        dispatch({ type: SET_CURRENT_ORDER, payload: record });
        dispatch({ type: SET_ORDER, payload: { key: record.id, value: record } });
        const files = await sharepointService.fetchFiles(record.folder, record.entityName!);
        const [grouped , ungrouped]:ReturnValue<GrouppedEnvelope, Document> = groupByEnvelope(files.data);
        setDocuments((prev)=>({
          ...prev,
          grouped,
          ungrouped
        }));
        if(customerEnvelopes.length){
          dispatch(setEnvelopes(customerEnvelopes));
        }
        if(loading){
          setLoading(false);
        }
      }catch(error){
        if(!axios.isCancel(error)){
           console.log(error);
           catchError(`/order/${match.params.id}`, getErrorMessage((error as AxiosError)));
        }
      }
    }
    orderService();

    window.addEventListener('refresh-documents', orderService);

    return () => {
      dispatch({ type: SET_CURRENT_ORDER, payload: undefined });
      window.removeEventListener('refresh-documents', orderService);
      source.cancel();
    };
  }, [dispatch]);



  const SalesOrderEnvelopeCreateHandler = (message:SalesOrderCreateI)=>{
    const {recordId , entityName} = message;
    if(match.params.id === recordId && entityName === entity_name){
      window.dispatchEvent(new Event("refresh-documents"));
    }
  }

  const SalesOrderEnvelopeUpdateHandler = (message:SalesOrderUpdateI)=>{
    if(match.params.id === message.recordId && entity_name === message.entityName){
      const envelopes:CustomerEnvelopes[] = store.getState().envelopes.envelopes
      const result = envelopes.filter( envelope => envelope.envelopeId === message.envelopeId)
      if(result.length) {
        const updatedEnvelope:CustomerEnvelopes = {...message};
        dispatch(changeEnvelopes(result[0].envelopeId, updatedEnvelope))
      }
    }
  }

  useEffect(()=>{
    if(connected && SignalRHub && !loading){
       SignalRHub.on(FileHubMethod.RecordEnvelopeCreate.toString(), SalesOrderEnvelopeCreateHandler);
       SignalRHub.on(FileHubMethod.RecordEnvelopeUpdate.toString(), SalesOrderEnvelopeUpdateHandler);

     return (): void => {
      SignalRHub.off(FileHubMethod.RecordEnvelopeCreate.toString());
      SignalRHub.off(FileHubMethod.RecordEnvelopeUpdate.toString());
     };
    }  
  },[connected, SignalRHub, loading])


  const successUploadHandler = (files: Document[]): void => {
    const docs = files.concat(documents.ungrouped);
    setDocuments( prevDocs => ({
      ...prevDocs,
      grouped:prevDocs.grouped,
      ungrouped:docs
    }));
  };

  const deleteHandler = (id: string): void => {
    const docs = documents.ungrouped.filter(x => x.id !== id);
    setDocuments( prevDocs => ({
      ...prevDocs,
      grouped:prevDocs.grouped,
      ungrouped:docs
    }));
  };

  
  return (
    <>
      <Header />
        <Container className={classes.container}>
          <Box className={classes.box} >
            <Link className={classes.link} to='/'>
              <ArrowBack />
            </Link>
            <Typography className={classes.back}>{Labels.Back}</Typography>
          </Box>
          <div className={classes.order_info}>
            {!order && <OrderSkeleton />}
            {order && <OrderInfo order={order} onUpload={() => setOpen(true)} />}
            <Documents 
              loading={loading} 
              documents={documents} 
              onDelete={deleteHandler}
              onReplace={replacementHandler}
              linksList={envelopes}
              folder={order?.folder}
            />
          </div>
        </Container>
        <UploadDialog open={open} onClose={setOpen} onUploadSuccess={successUploadHandler}/>
      <Footer />
    </>
  );
};

interface UploadDialogProps {
  open: boolean;
  onClose(flag: boolean): void;
  onUploadSuccess(files: Document[]): void;
}

const UploadDialog: FunctionComponent<UploadDialogProps> = (props: UploadDialogProps): JSX.Element => {
  const [files, setFiles] = useState<File[]>([]);
  const folder = useSelector<RootState, string>(state => state.orders.order?.folder);
  const [loading, setLoading] = useState<boolean>(false);

  const changeHandler = (fileList: FileList) => {
    const length = fileList.length;
    const result = [];
    for (let i = 0; i < length; i++) {
      result.push(fileList[i]);
    }
    result.push(...files);
    setFiles(result);
  };

  const handleClose = () => {
    setFiles([])
    props.onClose(false);
  };

  const deleteFileHandler = (fileName:string)=>{
    setFiles([...files.filter( file => file.name !== fileName)])
  }

  const upload = async (): Promise<void> => {
    setLoading(true);
    // TODO: Change on file
    const filePromises = map<File, Promise<Base64File>>(files, readFileAsBase64Async);
    const base64Files = await Promise.all(filePromises);
    const uploadFileModels = base64Files.map((f, i) => toUploadFileModel(f, i, base64Files.length));
    const fileUploadPromise = uploadFileModels.map(f => {
      return sharepointService.uploadFileAsync({ folderName: folder, fileToUpload: f });
    });

    try {
      const result = await Promise.all(fileUploadPromise);
      props.onUploadSuccess(result.map(r => r.data));
      handleClose();
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false);
    }
  };

  const calculateSequenceNumber = (index:number , numberOfFiles:number):string=>{
     if(index < 9){
       return `0${index + 1}`
     }

     return `${index + 1}`
  }

  // TODO: add created by
  const toUploadFileModel = (file: Base64File, index:number, numberOfFiles:number): UploadFileModel => {
    return {
      name: numberOfFiles === 1 ? 
        `${getFileName(file.name)}_${new Date().getTime()}.${getExtension(file.name)}` : 
        `${getFileName(file.name)}_${new Date().getTime()}_${calculateSequenceNumber(index, numberOfFiles)}.${getExtension(file.name)}`,
      bodyBase64: removeBase64Prefix(file.body),
      availableForCustomer: true,
      canBeDeletedByCustomer: false,
      documentType: '',
      comment: '',
      //createdBy: 'Test',
      documentSigned: false
      //modifiedBy: 'Test'
    };
  };

  const renderStartIcon = (): ReactElement | undefined => {
    if (loading) {
      return <Loader className='height-15' />;
    }
  };

  return (
    <Modal onClose={handleClose} open={props.open}>
      <Container style={{ background: 'white', paddingTop: '20px' }}>
        <DndArea onFileListChange={changeHandler} />
        <DocumentList files={files} onDeleteFile={deleteFileHandler}/>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingBottom: '20px' }}>
          <ButtonGroup color='primary' aria-label='outlined primary button group'>
            <Button onClick={handleClose} color='primary' disabled={loading} variant='outlined'>
              Schließen
            </Button>
            <Button
              onClick={upload}
              color='primary'
              variant='outlined'
              disabled={loading}
              startIcon={renderStartIcon()}
              autoFocus
            >
              Hochladen
            </Button>
          </ButtonGroup>
        </div>
      </Container>
    </Modal>
  );
};

export default Order;
