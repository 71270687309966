export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FETCH_PROTECTED_DATA_REQUEST = 'FETCH_PROTECTED_DATA_REQUEST';
export const RECEIVE_PROTECTED_DATA = 'RECEIVE_PROTECTED_DATA';

export const SET_ORDER = 'SET_ORDER';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const HEADER_HEIGHT = 'HEADER_HEIGHT';
export const FOOTER_HEIGHT = 'FOOTER_HEIGHT';

export const SET_DSE = 'SET_DSE';
export const UPDATE_DSE = 'UPDATE_DSE';

export const SET_CONNECTED = 'SET_CONNECTED';
export const SET_DISCONNECTED = 'SET_DISCONNECTED';

export const SET_ENVELOPES = 'SET_ENVELOPES';
export const CHANGE_ENVELOPE = 'CHANGE_ENVELOPE';
export const DELETE_ENVELOPE = 'DELETE_ENVELOPE';

export const SEND_PRE_REQUEST = 'SEND_PRE_REQUEST';
export const DENY_PRE_REQUEST = 'DENY_PRE_REQUEST';
