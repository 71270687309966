import React, {ReactElement, ReactSVGElement} from 'react';
import classes from './order.module.scss';

export const PdfIcon = ():ReactElement<ReactSVGElement>=>{
    return(
      <svg className={classes.pdf_icon_image} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
       <rect width="48" height="48" rx="24" fill="#828282"/>
       <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7531 25.169C18.431 25.169 18.1698 25.4302 18.1698 25.7523V29.2523C18.1698 29.5745 18.431 29.8357 18.7531 29.8357C19.0753 29.8357 19.3365 29.5745 19.3365 29.2523V28.6695H19.9167C20.8833 28.6695 21.6669 27.8859 21.6669 26.9192C21.6669 25.9526 20.8833 25.169 19.9167 25.169H18.7531ZM19.9167 27.5028H19.3365V26.3357H19.9167C20.239 26.3357 20.5003 26.5969 20.5003 26.9192C20.5003 27.2415 20.239 27.5028 19.9167 27.5028Z" fill="white"/>
       <path d="M27.4973 25.7516C27.4977 25.4297 27.7587 25.169 28.0806 25.169H29.8365C30.1586 25.169 30.4198 25.4302 30.4198 25.7523C30.4198 26.0745 30.1586 26.3357 29.8365 26.3357H28.6632L28.6617 27.5043H29.8365C30.1586 27.5043 30.4198 27.7655 30.4198 28.0877C30.4198 28.4098 30.1586 28.671 29.8365 28.671H28.6625L28.6639 29.2509C28.6647 29.573 28.4042 29.8349 28.0821 29.8357C27.7599 29.8365 27.4981 29.576 27.4973 29.2538L27.4943 28.0892L27.4943 28.0869L27.4973 25.7516Z" fill="white"/>
       <path fill-rule="evenodd" clip-rule="evenodd" d="M23.4167 25.169C23.0945 25.169 22.8333 25.4302 22.8333 25.7523V29.2523C22.8333 29.5745 23.0945 29.8357 23.4167 29.8357H23.9971C25.2858 29.8357 26.3305 28.791 26.3305 27.5024C26.3305 26.2137 25.2858 25.169 23.9971 25.169H23.4167ZM24 28.669V26.3357C24.643 26.3372 25.1638 26.859 25.1638 27.5023C25.1638 28.1457 24.643 28.6675 24 28.669Z" fill="white"/>
       <path fill-rule="evenodd" clip-rule="evenodd" d="M24 19.3333V12.3333H17C15.7113 12.3333 14.6667 13.3779 14.6667 14.6666V22.4464C13.9769 22.7741 13.5 23.4771 13.5 24.2916V30.7083C13.5 31.5227 13.9769 32.2258 14.6667 32.5534V33.3333C14.6667 34.6219 15.7113 35.6666 17 35.6666H31C32.2887 35.6666 33.3333 34.6219 33.3333 33.3333V32.5534C34.0231 32.2258 34.5 31.5227 34.5 30.7083V24.2916C34.5 23.4771 34.0231 22.7741 33.3333 22.4464V21.6666H26.3333C25.0447 21.6666 24 20.6219 24 19.3333ZM15.5417 23.9999C15.3806 23.9999 15.25 24.1305 15.25 24.2916V30.7083C15.25 30.8693 15.3806 30.9999 15.5417 30.9999H32.4583C32.6194 30.9999 32.75 30.8693 32.75 30.7083V24.2916C32.75 24.1305 32.6194 23.9999 32.4583 23.9999H15.5417Z" fill="white"/>
       <path d="M25.75 19.3333V12.9166L32.75 19.9166H26.3333C26.0112 19.9166 25.75 19.6554 25.75 19.3333Z" fill="white"/>
     </svg>
    )
  }

  export const SigningIcon = ():ReactElement<ReactSVGElement>=>{
    return(
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.20829 12.75C5.11784 12.75 4.69314 13.2354 4.37053 14.3119L4.31401 14.5096C4.16193 15.0573 4.071 15.2214 3.88681 15.2973C3.67088 15.3863 3.52692 15.3737 3.30062 15.2573L3.17818 15.1887C3.13414 15.1623 3.08612 15.1318 3.03182 15.0959L2.47914 14.7197C1.9659 14.3822 1.50473 14.159 0.943213 14.0187C0.608339 13.9349 0.269005 14.1386 0.185287 14.4734C0.10157 14.8083 0.30517 15.1476 0.640041 15.2313C0.979248 15.3161 1.27187 15.4445 1.59447 15.6392L1.8433 15.7982L2.24248 16.0715C2.43396 16.2017 2.57851 16.2916 2.72909 16.369C3.26482 16.6445 3.7873 16.6903 4.36311 16.453C4.98378 16.1972 5.2341 15.8031 5.46836 15.0183L5.59822 14.5668C5.74934 14.0757 5.85234 14 6.20829 14C6.46611 14 6.73295 14.129 7.0986 14.4358L7.25256 14.5699L8.01996 15.2994C9.19341 16.4024 10.1938 16.9379 11.6261 16.9379C13.0313 16.9379 14.2467 16.7263 15.2669 16.2972L12.8106 15.627C12.4378 15.6676 12.0431 15.6879 11.6261 15.6879C10.6405 15.6879 9.93297 15.3421 9.05424 14.5525L8.83906 14.3535L8.39587 13.9276C8.19866 13.7386 8.0509 13.6031 7.90212 13.4783C7.33246 13.0003 6.81469 12.75 6.20829 12.75ZM2.64135 1.47473C1.4768 2.63928 1.4768 4.5274 2.64135 5.69195L2.88151 5.93254C1.92195 7.10033 1.95663 8.33795 2.84904 9.23297L4.51674 10.9007C4.76103 11.1445 5.15676 11.1442 5.40062 10.8999C5.64449 10.6556 5.64414 10.2599 5.39985 10.016L3.73421 8.35038C3.33003 7.94501 3.2833 7.44176 3.76739 6.81841L10.9483 13.9989C11.179 14.2296 11.466 14.3961 11.7808 14.482L16.0438 15.6447C16.5104 15.7719 16.9385 15.3438 16.8113 14.8772L15.6486 10.6142C15.5628 10.2993 15.3962 10.0124 15.1655 9.78167L6.85857 1.47473C5.69402 0.31018 3.8059 0.31018 2.64135 1.47473Z" fill="white"/>
      </svg> 
    )
  }

  export const SignedIcon =():ReactElement<ReactSVGElement>=>{
    return (
      <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M6.70829 12.7498C5.61784 12.7498 5.19314 13.2352 4.87053 14.3117L4.81401 14.5094C4.66193 15.0571 4.571 15.2212 4.38681 15.2971C4.17088 15.3861 4.02692 15.3734 3.80062 15.2571L3.67818 15.1885C3.63414 15.162 3.58612 15.1315 3.53182 15.0956L2.97914 14.7195C2.4659 14.3819 2.00473 14.1588 1.44321 14.0184C1.10834 13.9347 0.769005 14.1383 0.685287 14.4732C0.60157 14.8081 0.80517 15.1474 1.14004 15.2311C1.47925 15.3159 1.77187 15.4443 2.09447 15.6389L2.3433 15.7979L2.74248 16.0713C2.93396 16.2015 3.07851 16.2914 3.22909 16.3688C3.76482 16.6442 4.2873 16.6901 4.86311 16.4528C5.48378 16.197 5.7341 15.8029 5.96836 15.0181L6.09822 14.5666C6.24934 14.0755 6.35234 13.9998 6.70829 13.9998C6.96611 13.9998 7.23295 14.1287 7.5986 14.4356L7.75256 14.5697L8.51996 15.2991C9.69341 16.4021 10.6938 16.9377 12.1261 16.9377C13.5313 16.9377 14.7467 16.726 15.7669 16.297L13.3106 15.6268C12.9378 15.6673 12.5431 15.6877 12.1261 15.6877C11.1405 15.6877 10.433 15.3419 9.55424 14.5522L9.33906 14.3533L8.89587 13.9273C8.69866 13.7383 8.5509 13.6029 8.40212 13.478C7.83246 13 7.31469 12.7498 6.70829 12.7498ZM3.14135 1.47449C1.9768 2.63904 1.9768 4.52715 3.14135 5.69171L3.38151 5.9323C2.42195 7.10009 2.45663 8.33771 3.34904 9.23273L5.01674 10.9004C5.26103 11.1443 5.65676 11.1439 5.90062 10.8997C6.14449 10.6554 6.14414 10.2596 5.89985 10.0158L4.23421 8.35013C3.83003 7.94477 3.7833 7.44151 4.26739 6.81817L11.4483 13.9986C11.679 14.2294 11.966 14.3959 12.2808 14.4818L16.5438 15.6444C17.0104 15.7717 17.4385 15.3435 17.3113 14.877L16.1486 10.6139C16.0628 10.2991 15.8962 10.0122 15.6655 9.78143L7.35857 1.47449C6.19402 0.309936 4.3059 0.309936 3.14135 1.47449Z" fill="#D1050C"/>
      </svg> 
    )
  }

  export const DeleteIcon =():ReactElement<ReactSVGElement>=>{
    return (
     <svg className={classes.delete_icon_image} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.9163 5.5C17.9163 5.92736 17.5946 6.27959 17.1802 6.32773L17.083 6.33333H16.3791L15.3529 16.766C15.2375 17.9391 14.251 18.8333 13.0722 18.8333H6.92712C5.74834 18.8333 4.76184 17.9391 4.64646 16.766L3.62029 6.33333H2.91634C2.4561 6.33333 2.08301 5.96024 2.08301 5.5C2.08301 5.03976 2.4561 4.66667 2.91634 4.66667H7.08301C7.08301 3.05584 8.38884 1.75 9.99967 1.75C11.6105 1.75 12.9163 3.05584 12.9163 4.66667H17.083C17.5432 4.66667 17.9163 5.03976 17.9163 5.5ZM11.8747 8.20833C11.5583 8.20833 11.2968 8.44346 11.2554 8.74852L11.2497 8.83333V14.6667L11.2554 14.7515C11.2968 15.0565 11.5583 15.2917 11.8747 15.2917C12.1911 15.2917 12.4526 15.0565 12.494 14.7515L12.4997 14.6667V8.83333L12.494 8.74852C12.4526 8.44346 12.1911 8.20833 11.8747 8.20833ZM8.12467 8.20833C7.80826 8.20833 7.54677 8.44346 7.50538 8.74852L7.49967 8.83333V14.6667L7.50538 14.7515C7.54677 15.0565 7.80826 15.2917 8.12467 15.2917C8.44109 15.2917 8.70258 15.0565 8.74397 14.7515L8.74967 14.6667V8.83333L8.74397 8.74852C8.70258 8.44346 8.44109 8.20833 8.12467 8.20833ZM9.99967 3.41667C9.30932 3.41667 8.74967 3.97631 8.74967 4.66667H11.2497C11.2497 3.97631 10.69 3.41667 9.99967 3.41667Z" fill="black"/>
     </svg>
     
    )
  }

  export const OpenInNewIcon = ():ReactElement<ReactSVGElement>=>{
    return (
     <svg className={classes.open_icon_image} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M4.87184 0.5L17.1292 0.501971C19.4671 0.503026 21.3763 2.33719 21.4977 4.6452L21.5038 4.87753V10.1188C21.5038 10.6021 21.1121 10.9938 20.6288 10.9938C20.1858 10.9938 19.8198 10.6647 19.7618 10.2376L19.7538 10.1188V4.87779C19.7534 3.48841 18.6741 2.35105 17.3084 2.25811L17.1287 2.25197L4.87103 2.25C3.49708 2.24895 2.3693 3.30467 2.25557 4.64968L2.24642 4.83482L2.24946 17.127C2.25015 18.5164 3.32966 19.6536 4.69538 19.7463L4.87507 19.7524H10.1208C10.6041 19.7524 10.9958 20.1442 10.9958 20.6274C10.9958 21.0704 10.6666 21.4365 10.2395 21.4945L10.1208 21.5024H4.8748C2.53699 21.5017 0.627477 19.6679 0.505636 17.3599L0.499461 17.1276L0.497314 4.87807L0.502242 4.64074C0.619382 2.4092 2.40858 0.621003 4.63953 0.50589L4.87184 0.5ZM8.59765 7.50206L15.6625 7.5014C16.3068 7.5014 16.8292 8.02374 16.8292 8.66807C16.8292 9.26638 16.3788 9.7595 15.7986 9.82689L15.6625 9.83474L11.4861 9.8345L19.1206 17.4666C19.5413 17.887 19.5738 18.5488 19.218 19.0065L19.121 19.1165C18.7005 19.5371 18.0387 19.5696 17.581 19.2139L17.4711 19.1168L9.83181 11.4807L9.83206 15.6695C9.83206 16.2678 9.38168 16.7609 8.80145 16.8283L8.66539 16.8361C8.06708 16.8361 7.57397 16.3858 7.50657 15.8055L7.49872 15.6695V8.66807L7.50278 8.57011L7.52087 8.44691L7.53135 8.3936L7.55351 8.31696L7.59591 8.20144L7.6703 8.05941C7.71876 7.98002 7.77627 7.90721 7.84139 7.84218L7.96897 7.73157L8.11101 7.64056L8.22984 7.58461L8.33325 7.54858L8.46305 7.51797L8.59765 7.50206L15.6625 7.5014L8.59765 7.50206Z" fill="#212121"/>
     </svg>
     
    )
  }