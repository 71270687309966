import React, { FunctionComponent } from 'react';
import { List } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Document from './document';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    }
  })
);

interface DocumentList {
  files: File[];
  onDeleteFile:(fileName:string)=>void
}

const DocumentList: FunctionComponent<DocumentList> = (props: DocumentList): JSX.Element => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {props.files.map((file: File, i: number) => (
        <Document key={file.name + i} file={file} deleteHandler={props.onDeleteFile}/>
      ))}
    </List>
  );
};

export default DocumentList;
