import { LOGIN_USER_SUCCESS, LOGOUT_USER , 
         HEADER_HEIGHT, FOOTER_HEIGHT, 
         SET_DSE, SET_CONNECTED, 
         SET_DISCONNECTED, UPDATE_ORDER, 
         UPDATE_DSE, SET_ENVELOPES,
         CHANGE_ENVELOPE , DELETE_ENVELOPE,
         SEND_PRE_REQUEST , DENY_PRE_REQUEST} from '../unit/reducerTypes';
import {CustomerEnvelopes} from '../services/sales-order-service';
import {DseResponse} from '../services/sales-order-service';
import { HubConnection } from '@microsoft/signalr';

export const loginUserSuccess = (token: string) => {
  sessionStorage.setItem('token', token);
  return {
    type: LOGIN_USER_SUCCESS,
    payload: {
      token
    }
  };
};

export const logout = ()=> {
  sessionStorage.removeItem('token');
  return {
    type: LOGOUT_USER
  };
};




export const setFooterHeight = (height:number)=>(
  {
    type:FOOTER_HEIGHT,
    payload:height
  }
)

export const setHeaderHeight = (height:number)=>(
  {
    type:HEADER_HEIGHT,
    payload:height
  }
)

export const setDseInfo = (dse:DseResponse)=>(
  {
     type:SET_DSE,
     payload:dse
  }
)

export const updateDseInfo = (dse:DseResponse)=>({
  type:UPDATE_DSE,
  payload:dse
})


export const setConnection = (hubConnection:HubConnection)=>({
  type:SET_CONNECTED,
  payload:hubConnection
})

export const setDisconnected = ()=>({
  type:SET_DISCONNECTED,
})



export const updateOrder = (orderId:string, envelopeAvailable:boolean)=>({
  type: UPDATE_ORDER,
  payload:{
    key:orderId,
    envelopeAvailable
  }
})

export const setEnvelopes = (envelopes:CustomerEnvelopes[])=>({
  type:SET_ENVELOPES,
  payload:envelopes
})

export const changeEnvelopes = (envelopeId:string, updatedEnvelope:CustomerEnvelopes)=>({
  type:CHANGE_ENVELOPE,
  payload:{
    envelopeId,
    updatedEnvelope
  }
})


export const deleteEnvelope = (envelopeId:string)=>({
  type:DELETE_ENVELOPE,
  payload:envelopeId
})

export const sendDsePreRequestMessage = ()=>({
  type:SEND_PRE_REQUEST
})

export const denyDsePreRequestMessage = ()=>({
  type: DENY_PRE_REQUEST
})

