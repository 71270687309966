import React, {FunctionComponent , ReactElement} from 'react'
import {CircularProgress, Backdrop} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

const Overlay:FunctionComponent = ():ReactElement=>{
   const classes = useStyles();

    return (
      <Backdrop className={classes.backdrop} open={true}>
         <CircularProgress color="inherit" />
      </Backdrop>
    )
}

export default Overlay;