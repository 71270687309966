import React, { useEffect , FunctionComponent, ReactElement } from 'react';
import {useMeasure} from 'react-use';
import classes from './footer.module.scss';
import { Container} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setFooterHeight } from '../../actions';

const Footer: FunctionComponent = (): ReactElement => {
  const [ref , {height}] = useMeasure<HTMLElement>()
  const dispatch = useDispatch()

  useEffect(()=>{
   if(!!height){
    dispatch(setFooterHeight(height))
   }
  },[height])

  return (
    <footer ref={ref}>
      <div className={classes.footer__meta}> 
          <Container className={classes.container} >
            <div className={classes.menu_block}>
              <div className={classes.metaMenu}>
                <ul>
                  <li>
                    <a href='https://www.lueg.de/impressum/' target='_blank' rel='noopener noreferrer'>
                      Impressum
                    </a>
                  </li>
                  <li>
                    <a href='https://www.lueg.de/menue/meta/datenschutz/' target='_blank' rel='noopener noreferrer'>
                      Datenschutz
                    </a>
                  </li>
                  <li>
                    <a href='https://www.lueg.de/menue/meta/streitbeilegung/' target='_blank' rel='noopener noreferrer'>
                      Streitbeilegung
                    </a>
                  </li>
                  <li>
                    <a href='https://www.lueg.de/menue/meta/kontakt/' target='_blank' rel='noopener noreferrer'>
                      Kontakt
                    </a>
                  </li>
                  <li>
                    <a href='https://www.lueg.de/karriere-bei-lueg/' target='_blank' rel='noopener noreferrer'>
                      Karriere
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            
            <div className={classes.info_block}>
              <div className={classes.info}>
                <p>Fragen, Wünsche oder Anregungen:</p>
                <p>0201 2065-0</p>
              </div>
            </div>
          </Container> 
      </div>
    </footer>
  );
};

export default Footer;
