import React, { FunctionComponent, ReactElement } from 'react';
import {
  CircularProgress,
  List,
  Typography
} from '@material-ui/core';
import authService from '../../services/auth-service';
import { getErrorMessage , CustomerEnvelopeStatus} from '../../unit/constants';
import sharepointService, { Document } from '../../services/sharepoint-service';
import {CustomerEnvelopes} from '../../services/sales-order-service';
import { withSnackbar, SnackbarProps } from '../../hoc/withSnackbar';
import {DocumentsScope} from './order';
import {EnvelopeList , DocumentsBelonging} from './envelope-list';
import {SHAREPOINT_SERVICE_URI} from '../../unit/url';
import classes from './order.module.scss';



interface DocumentsProps {
  loading: boolean;
  documents: DocumentsScope;
  onDelete(id: string): void;
  onReplace(envelopeId:string, type:DocumentsBelonging):void;
  linksList: CustomerEnvelopes[];
  folder:string | undefined;
}

type Props = DocumentsProps & SnackbarProps;


const Documents: FunctionComponent<Props> = ({
  loading,
  documents,
  onDelete,
  showSnackbar,
  onReplace,
  linksList,
  folder
}: Props): ReactElement => {
  const EnvelopeListType = 'envelope-list';
  const StandaloneType= 'standalone';

  const handleDocumentOpen = async (id: string, name: string):Promise<void> => {
    try{
      const fileToken = await authService.issueFileToken(id);
      let url = `${SHAREPOINT_SERVICE_URI}/api/sharepoint-library/file/${fileToken}/${name}`;
      window.location.assign(url);
    }catch(error:any){
      console.log(error);
      showSnackbar(getErrorMessage(error));
    }
  };

  
  const handleDelete = async (id: string, deleteCallback:()=>void): Promise<void> => {
    try {
      await sharepointService.deleteAsync(id);
      onDelete(id);
    } catch (error:any) {
      deleteCallback();
      showSnackbar(getErrorMessage(error));
    }
  };

  const isAnimationExist = (envelopeName:string | null):boolean=>{
    const target = linksList.find( item => item.envelopeId === envelopeName)
    const findStatus = target && (target.status === CustomerEnvelopeStatus.Deleted || target.status === CustomerEnvelopeStatus.Completed)
    
    return !!target && !!findStatus
    
  }

  const renderContent = ():JSX.Element[] | JSX.Element=>{ 
     if(loading){
       return (
        <div className='text-center'>
           <CircularProgress color='primary' style={{ margin: 'auto' }} />
        </div>
       )
     }

     if(!loading && Object.keys(documents.grouped).length === 0 && documents.ungrouped.length === 0){
        return (
          <Typography className='text-center'>Keine Daten</Typography>
        )
     }
     
     const envelopes = Object.keys(documents.grouped)
     const withEnvelope:JSX.Element[] = envelopes.map( (envelopeName:string, index:number) => (
      < EnvelopeList 
          showSnackbar={showSnackbar} 
          key={envelopeName}
          type={EnvelopeListType}
          isAnimate={isAnimationExist(envelopeName)}
          positionInList={index}
          listLength={envelopes.length}
          envelopeName={envelopeName} 
          documents={documents.grouped[envelopeName]} 
          linksList={linksList} 
          onOpen={handleDocumentOpen} 
          onDelete={handleDelete}
          animateReplaceHandler={onReplace}
          folder={folder}
       />
     ))
     
     const withoutEnvelope = documents.ungrouped.map( (doc:Document, index:number)=> (
      < EnvelopeList 
          key={doc.id} 
          documents={[doc]}
          sequenceIndex={index + 1}
          sequenceLgth={documents.ungrouped.length}
          type={StandaloneType}
          envelopeName={doc.envelopeData}
          isAnimate={isAnimationExist(doc.envelopeData)}
          onOpen={handleDocumentOpen} 
          onDelete={handleDelete}
          animateReplaceHandler={onReplace}
      />
     ))
     
     return [...withEnvelope, ...withoutEnvelope]
  }

   
  return (
    <>
    <List className={classes.list}>
      {renderContent()}
    </List>
    </>
  );
};

export default withSnackbar(Documents);
