import React, { useState, FunctionComponent, ReactElement, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import AuthService from '../../services/auth-service';
import { useDispatch } from 'react-redux';
import { loginUserSuccess } from '../../actions';
import { RouteComponentProps } from 'react-router-dom';
import CodeForm from './code-form';
import PhoneForm from './phone-form';
import { withSnackbar, SnackbarProps } from '../../hoc/withSnackbar';
import EmailForm from './email-form';
import {AccountsForm} from './accounts-form';
import { getErrorMessage } from '../../unit/constants';
import { useLoginStyles } from './useStyles';
import { IconButton } from '@material-ui/core';
import FormSelector from './form-selector';
import {User} from '../../services/auth-service';
import { LoginFormType } from './login-form-state';

export const ArrowBack = ():ReactElement=>{

  return (
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.29297 16.7121C8.68638 17.0997 9.31952 17.095 9.70714 16.7016C10.0948 16.3082 10.0901 15.6751 9.69667 15.2874L4.32638 9.99609L17.9975 9.99609C18.5498 9.99609 18.9975 9.54838 18.9975 8.99609C18.9975 8.44381 18.5498 7.99609 17.9975 7.99609L4.33286 7.99609L9.69667 2.71115C10.0901 2.32352 10.0948 1.69038 9.70714 1.29697C9.31952 0.903565 8.68638 0.898876 8.29297 1.2865L1.36878 8.10888C0.871866 8.59849 0.871865 9.40008 1.36878 9.88969L8.29297 16.7121Z" fill="#D1050C"/>
    </svg>
  )
}

type SignInSideProps = RouteComponentProps<{}, any, { from?: string }> & SnackbarProps;

const SignInSide: FunctionComponent<SignInSideProps> = (props: SignInSideProps): ReactElement => {
  const dispatch = useDispatch();
  const classes = useLoginStyles();
  const [loading, setLoading] = useState(false);
  const [email , setEmail] = useState<string>('');
  const [users , setUsers] = useState<User[]>([]);
  const [stepSequenceHistory , setStepSequenceHistory] = useState<LoginFormType[]>([LoginFormType.email]);
  const [selectedUser , setSelectedUser] = useState<User | null>(null);
  const [formState, setFormState] = useState<LoginFormType>(LoginFormType.email);
  const history = useHistory();

  const submitUserName = async (userName: string) => {
    setLoading(true);
    try {
      const {data} = await AuthService.verifyEmail(userName);
      setEmail(userName);
      if(data.status === 1){
        setUsers(data!.users as User[]);
        setFormState(LoginFormType.accounts);
      }else{
        setFormState(LoginFormType.code);
      }
      setLoading(false);
    } catch(error:any){
      console.log(error);
      setLoading(false);
      props.showSnackbar(getErrorMessage(error));
    } finally {}
  };

  const submitCodeHandler = async (code: string) => {
    setLoading(true);
    try {
      const { data } = await AuthService.issueTokenAsync(email, code, selectedUser ? selectedUser.guid : null);
      if(data.status === 2){
        dispatch(loginUserSuccess(data.access_token.access_token));
        setLoading(false);
        history.push(props.location.state?.from || '/')
      }
    } catch(error:any){
      setLoading(false);
      props.showSnackbar(getErrorMessage(error));
    } finally {}
  };

  const onSelectAccountHandler = async (accountGuid:string)=>{
     setLoading(true);
     try{
      const {status} = await AuthService.approveUserAuthentication(email , accountGuid);
      if(!status){
        setSelectedUser(users.find( user => user.guid === accountGuid)!);
        setStepSequenceHistory( steps => [...steps , LoginFormType.accounts]);
        setFormState(LoginFormType.code);
      }
      setLoading(false);
     }catch(error:any){
       console.log(error);
       setLoading(false);
       props.showSnackbar(getErrorMessage(error));
     }
  }

 
  const formTypeHandler = (type: LoginFormType): void => {
    setFormState(type);
  };

  const renderForm = (type: LoginFormType): ReactNode => {
    switch (type) {
      case LoginFormType.email: {
        return <EmailForm onSubmit={submitUserName} loading={loading}/>;
      }
      case LoginFormType.accounts: {
        return <AccountsForm users={users} selectedUser={selectedUser} selectAccountHandler={onSelectAccountHandler} email={email} loading={loading}/>
      }
      case LoginFormType.phone: {
        return <PhoneForm onSubmit={submitUserName} />;
      }
      case LoginFormType.code: {
        return <CodeForm onSubmit={submitCodeHandler} loading={loading}/>;
      }

      default: {
        return <FormSelector onSelect={formTypeHandler} />;
      }
    }
  };

  const backHandler = (): void => {
    if(stepSequenceHistory.length === 1){
      return setFormState(LoginFormType.email);
    }
    const lastStep = stepSequenceHistory[stepSequenceHistory.length - 1];
    setStepSequenceHistory(stepSequenceHistory.slice(0 , 1));
    setFormState(lastStep);
  };

  const AnmeldenIcon = ():ReactElement=>{
    return (
      <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
         <rect width="68" height="68" rx="34" fill="#D1050C"/>
         <path d="M34 16.5C37.866 16.5 41 19.634 41 23.5V27H44.0625C46.2371 27 48 28.7629 48 30.9375V47.5625C48 49.7371 46.2371 51.5 44.0625 51.5H23.9375C21.7629 51.5 20 49.7371 20 47.5625V30.9375C20 28.7629 21.7629 27 23.9375 27H27V23.5C27 19.634 30.134 16.5 34 16.5ZM44.0625 29.625H23.9375C23.2126 29.625 22.625 30.2126 22.625 30.9375V47.5625C22.625 48.2874 23.2126 48.875 23.9375 48.875H44.0625C44.7874 48.875 45.375 48.2874 45.375 47.5625V30.9375C45.375 30.2126 44.7874 29.625 44.0625 29.625ZM34.0002 36.625C35.45 36.625 36.6252 37.8003 36.6252 39.25C36.6252 40.6997 35.45 41.875 34.0002 41.875C32.5505 41.875 31.3752 40.6997 31.3752 39.25C31.3752 37.8003 32.5505 36.625 34.0002 36.625ZM34 19.125C31.5838 19.125 29.625 21.0838 29.625 23.5V27H38.375V23.5C38.375 21.0838 36.4162 19.125 34 19.125Z" fill="white"/>
      </svg>
    )
  }

  return (
    <>
      {loading && <LinearProgress color='secondary' className={classes.progress} />}
      <Grid container component='main' className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square className='relative'>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <AnmeldenIcon />
            </Avatar>
            <Typography className={classes.title}>
               Anmelden
            </Typography>
            {renderForm(formState)}
          </div>
          {(formState === LoginFormType.code || formState === LoginFormType.accounts)  && (
            <IconButton className={classes.back} onClick={backHandler}>
              <ArrowBack />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(SignInSide);
