import React, { useState, useEffect, useRef, RefObject, FormEvent, ReactNode, FocusEvent, ChangeEvent, ReactElement, FunctionComponent } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import { useStyles } from './useStyles';
import {LabelContent} from './label-content';

interface SubmitCodeFormProps {
  onSubmit(code: string): void;
  loading:boolean;
}

interface CredentialRequestOptions {
  otp: OTPOptions;
  signal?: AbortSignal;
}

interface OTPOptions {
  transport: string[];
}

interface Credential{
  code?:string | number,
  [key:string]:any
}

const SubmitCodeForm: FunctionComponent<SubmitCodeFormProps> = (props: SubmitCodeFormProps): ReactElement => {
  const classes = useStyles();
  const [code, setCode] = useState<string>('');
  const [focus, setFocus] = useState<number>(0);
  const inputRef:RefObject<HTMLInputElement> | null = useRef<HTMLInputElement | null>(null);

  const getSMSCode=()=>{
    const ac = new AbortController();
    let options : CredentialRequestOptions = {
      otp: { transport: ['sms'] }
    };

    navigator.credentials.get({
      ...options,
      signal: ac.signal
    }).then( (otp:Credential | null) => {
      if(otp && otp?.code){
        const code = otp.code.toString()
        setCode(code)
        inputRef.current!.value = code
      }
    }).catch(err => {
      console.log(err);
    });
  }

  useEffect(()=>{
    if(inputRef.current){
       if('OTPCredential' in window){
         getSMSCode();
       }
    } 
  }, [inputRef.current])

  const submitHandler = (event: FormEvent): void => {
    if(props.loading){
      return;
    }
    event.preventDefault();
    props.onSubmit(code);
  };

  const codeChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setCode(value);
  };

  const focusHandler = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>):void=>{
    setFocus( prev => prev + 1)
  }

  const blurHandler =(event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>):void=>{
    setFocus( prev => prev - 1)
  }

  const labelContent = ():ReactNode=>{
    return <LabelContent focus={focus} labelName={'Code'}/>
  }

  return (
    <form  className={classes.form} noValidate onSubmit={submitHandler}>
      <TextField
        variant='outlined'
        margin='normal'
        value={code}
        onChange={codeChangeHandler}
        fullWidth
        id='code'
        label={labelContent()}
        name='code'
        autoFocus
        type='text'
        inputRef={inputRef}
        inputMode='numeric'
        autoComplete='one-time-code'
        inputProps={{
          pattern:"[0-9]*",
          autoComplete:'one-time-code'
        }}
        className={classes.input}
        onFocus={focusHandler}
        onBlur={blurHandler}
      />
      <Typography className={classes.code_message}><span style={{color: '#D1050C'}}>*</span>Geben Sie den Code ein, den Sie per SMS erhalten haben</Typography>
      <Button type='submit' disabled={props.loading} fullWidth variant='contained' className={`${classes.submit} ${classes.code_spacing}`}>
         Anmelden
      </Button>
    </form>
  );
};

export default SubmitCodeForm;
