import React, {FunctionComponent , ReactElement} from 'react';

export const LoadProgress:FunctionComponent= ():ReactElement=>{

    return (
       
    <svg 
      style={{backgroundColor: 'white', width:'100%', height: '100%'}}
      width="200px" 
      height="200px" 
      viewBox="0 0 100 100" 
      preserveAspectRatio="xMidYMid"
    >
        <rect x="19" y="19" width="20" height="20" fill="#ed684b">
          <animate attributeName="fill" values="#edc864;#ed684b;#ed684b" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0s" calcMode="discrete"></animate>
        </rect>
        <rect x="40" y="19" width="20" height="20" fill="#ed684b">
          <animate attributeName="fill" values="#edc864;#ed684b;#ed684b" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.125s" calcMode="discrete"></animate>
        </rect>
        <rect x="61" y="19" width="20" height="20" fill="#ed684b">
          <animate attributeName="fill" values="#edc864;#ed684b;#ed684b" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.25s" calcMode="discrete"></animate>
        </rect>
        <rect x="19" y="40" width="20" height="20" fill="#ed684b">
          <animate attributeName="fill" values="#edc864;#ed684b;#ed684b" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.875s" calcMode="discrete"></animate>
        </rect>
        <rect x="61" y="40" width="20" height="20" fill="#ed684b">
          <animate attributeName="fill" values="#edc864;#ed684b;#ed684b" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.375s" calcMode="discrete"></animate>
        </rect>
        <rect x="19" y="61" width="20" height="20" fill="#ed684b">
          <animate attributeName="fill" values="#edc864;#ed684b;#ed684b" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.75s" calcMode="discrete"></animate>
        </rect>
        <rect x="40" y="61" width="20" height="20" fill="#ed684b">
          <animate attributeName="fill" values="#edc864;#ed684b;#ed684b" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.625s" calcMode="discrete"></animate>
        </rect>
        <rect x="61" y="61" width="20" height="20" fill="#ed684b">
          <animate attributeName="fill" values="#edc864;#ed684b;#ed684b" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.5s" calcMode="discrete"></animate>
        </rect>
    </svg>
    )
}