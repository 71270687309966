import { combineReducers } from 'redux';
import auth_reducer from './auth';
import orders_reducer from './orders';
import modal_reducer from './modal';
import dimensionsReducer from './dimensions';
import dseReducer from './dse';
import signal_R_Reducer from './signalR';
import envelopesReducer from './customer-envelopes';
import dseCreatedRequestStatusReducer from './dse-created-message';

const rootReducer = combineReducers({
  auth: auth_reducer,
  orders: orders_reducer,
  modal: modal_reducer,
  dimensions:dimensionsReducer,
  dse:dseReducer,
  signal_R:signal_R_Reducer,
  envelopes:envelopesReducer,
  sendDseCreatedMessage:dseCreatedRequestStatusReducer,

});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
