import React, { FormEvent, FunctionComponent, ReactElement, useState } from 'react';
import { Button } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useStyles } from './useStyles';

interface PhoneFormProps {
  onSubmit(phone: string): void;
}

const PhoneForm: FunctionComponent<PhoneFormProps> = (props: PhoneFormProps): ReactElement => {
  const classes = useStyles();
  const [phone, setPhone] = useState('');

  const submitHandler = (event: FormEvent): void => {
    event.preventDefault();
    const value = phone.replace(/[^0-9,\+$]/g, '');
    props.onSubmit(value);
  };

  const changeHandler = (phone: string): void => {
    setPhone(phone);
  };

  return (
    <form className={classes.form} noValidate onSubmit={submitHandler}>
      <MuiPhoneNumber
        defaultCountry='de'
        autoFocus
        fullWidth
        variant='outlined'
        margin='normal'
        required
        value={phone}
        onChange={changeHandler}
        id='phone'
        label='Telefonnummer'
        name='phone'
        autoComplete='phone'
      />
      <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
        Sende Einmal-Code per SMS um fortzufahren
      </Button>
    </form>
  );
};

export default PhoneForm;
