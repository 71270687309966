import React, { FunctionComponent , ReactElement } from 'react';
import { useSelector} from 'react-redux';
import {Statuses} from '../../unit/constants';
import {RootState} from '../../reducers';
import {DseState} from '../../reducers/dse';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {withSnackbar, SnackbarProps} from '../../hoc/withSnackbar';
import {DisplayStatuses} from '../../unit/constants';
import classes from './dse-card.module.scss';

interface DseCardProps extends SnackbarProps{};

  const DseCard:FunctionComponent<DseCardProps> = ({ showSnackbar }):ReactElement=>{
    const dseInfo = useSelector<RootState,DseState>((state:RootState) => state.dse);
    const {link , documentId, status, recordId, signedTimestamp} = dseInfo;
    const history = useHistory();
    const Created = status === Statuses.Created;

    const pdfReviewHandler = async ()=>{
      if(!documentId || Created){
        return;
      }
      const encoder = new TextEncoder();
      history.push(`/document/pdf/${encodeURIComponent(encoder.encode(documentId).toString())}`);
    }
   

    const displayCurrentStatus = (status:number | null)=>{
       switch(status){
         case 0:
          return DisplayStatuses.RequireSignature;
         case 1: 
          return  DisplayStatuses.Signed;
         case 2:
          return DisplayStatuses.NotAvailable;
         default : return '';
       }
    }

    return (
            <Grid item sm={6} md={4} xs={12} >
                <Card className={classes.signature_card} onClick={pdfReviewHandler}>
                  <CardActionArea className={classes.card_area}>
                    <CardContent>
                      <div className={classes.upper_section}>
                        <Typography className={classes.document_status}>
                           Status
                        </Typography>
                        <Typography className={classes.signature_status}>
                           {displayCurrentStatus(status)}
                        </Typography>
                      </div>
                      <Typography noWrap gutterBottom className={classes.data_protection}>
                         Datenschutzerklärung
                      </Typography>
                      <div className={classes.down_section}>
                       <Typography className={classes.underneath_link}>
                         { (Created && (link && recordId)) &&
                          (<a 
                            target='_blank' 
                            href={link}
                            rel='noopener noreferrer'  
                          > 
                            <span>UNTERSCHRIFT LEISTEN</span>
                          </a>)
                          }
                       </Typography>
                       <Typography className={classes.date}>
                          {
                          (status === Statuses.Signed && signedTimestamp) && 
                          (<div>{signedTimestamp}</div>)
                          }
                       </Typography>
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
            </Grid>
    )
}

export default withSnackbar(DseCard);