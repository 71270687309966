import {AxiosError} from 'axios';

export const getErrorMessage = (error: any): string => {
    let message = error.message;
    if (error.isAxiosError) {
      let ejectMessage = JSON.parse(JSON.stringify((error as AxiosError).response?.data))
      message = !!ejectMessage.length ? ejectMessage : message
    }
  
    return message as string;
  };