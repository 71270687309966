import React, {ReactElement, FunctionComponent} from 'react';
import { useStyles } from './useStyles';

interface LabelContentProps{
    focus:number,
    labelName:string
}

export const LabelContent:FunctionComponent<LabelContentProps> = ({focus , labelName}):ReactElement=>{
    const classes = useStyles();
    
      return (
             <div className={classes.label}>
               <div style={{color: !!focus ? '#000000' : '#828282' }}>{labelName}</div>
               {!!focus ? <div>*</div> : <></>}
            </div>
      )
    
}