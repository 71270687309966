import React, { useState, ComponentType, ReactElement } from 'react';
import { Button, Snackbar } from '@material-ui/core';

type HorizontalPlacement = 'center' | 'left' | 'right';
type VerticalPlacement = 'top' | 'bottom';

interface SnackbarState {
  open: boolean;
  message: string;
  vertical: VerticalPlacement;
  horizontal: HorizontalPlacement;
}

const snackbarDefaultState: SnackbarState = {
  open: false,
  message: '',
  vertical: 'bottom',
  horizontal: 'right'
};

export interface SnackbarProps {
  showSnackbar(message: string, vertical?:VerticalPlacement, horizontal?:HorizontalPlacement): void;
}

export function withSnackbar<P>(Component: ComponentType<P>) {
  return function (props: Omit<P, 'showSnackbar'>): ReactElement {
    const [snackbarState, setSnackbarState] = useState<SnackbarState>(snackbarDefaultState);

    const hideSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string): void => {
      if (reason === 'clickaway') {
        return;
      }
      closeSnackbar();
    };

    const showSnackbar = (message: string, vertical?:VerticalPlacement, horizontal?:HorizontalPlacement): void => {

      setSnackbarState({ open: true, message, vertical:vertical ?? 'bottom', horizontal: horizontal ?? 'right'});
    };

    const closeSnackbar = (): void => {
      setSnackbarState(snackbarDefaultState);
    };



    return (
      <>
        <Component {...(props as P)} showSnackbar={showSnackbar} />
        <Snackbar
          anchorOrigin={{
            vertical: snackbarState.vertical,
            horizontal: snackbarState.horizontal
          }}
          open={snackbarState.open}
          autoHideDuration={10000}
          onClose={closeSnackbar}
          message={snackbarState.message}
          action={
            <>
              <Button color='secondary' size='small' onClick={hideSnackbar}>
                Schließen
              </Button>
            </>
          }
        />
      </>
    );
  };
}
